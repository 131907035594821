import { MainLaylout } from "../component/MainLayout";
import { HeroHeader } from "../component/HeroHeader";

export function AboutUs() {
  return (
    <MainLaylout>
      <HeroHeader
        title={"About Us"}
        breadcrumb={[
          { link: "/", name: "Home" },
          { link: "/about", name: "About Us" },
        ]}
      />

      <div class="whole-wrap">
        <div class="container box_1170">
          <div class="section-top-border">
            <h3 class="mb-30">Welcome to Evyty</h3>
            <div class="row">
              <div class="col-md-3">
                <img
                  src="img/company/Evyty%20Front%20Entry.jpg"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <div class="col-md-9 mt-sm-20">
                <p>
                  Our company provides you with Soft furnishing FABRICS and
                  TRIMMINGS.
                </p>
                <p>
                  We are renowned for our curtain fabrics such as jacquard
                  fabrics, print fabrics, voile, organza etc. We stock over 3000
                  items of tiebacks, tassels and trimmings from 12 different
                  collections, such as tiebacks,tassels, tassel fringes, ruche
                  fringes, pompom fringes, gimps,flange cord, cord, rosette,
                  bead fringes, cushions and many more. Each collection features
                  a range of beautifully made trimmings in colours and styles.
                  Our exquisite jacquard fabrics also co-ordinate our trimmings
                  from gimps, key- tassels, tiebacks to fringes in the same
                  color range.
                </p>
              </div>
            </div>
          </div>
          <div class="section-top-border text-right">
            {/* <h3 class="mb-30">Right Aligned</h3> */}
            <div class="row">
              <div class="col-md-9">
                <p class="text-right">
                  Evyty Pty Ltd. 's philosophy is to make your living and
                  working environment more colorful and beautiful, satisfaction
                  and value for money!
                </p>
                <p class="text-right">
                  Established in 1992, Evyty is a Sydney based importer and
                  wholesaler of furnishing fabrics and trimmings with our Head
                  Office,Warehouse,Show-room and Work-room in Seven Hills.
                </p>
                <p class="text-right">
                  Evyty is a premier importer, stockiest and wholesaler of
                  quality furnishing fabrics and trimmings in Australia. Our
                  commitment is to provide only quality stocks, and the best
                  service by giving our customers, interior decorators,designers
                  and upholsterer free worry ordering and delivery.
                </p>
              </div>
              <div class="col-md-3">
                <img
                  src="img/company/Evyty%20Decoration%20Design%20Photo.jpg"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="section-top-border">
            {/* <h3 class="mb-30">Left Aligned</h3> */}
            <div class="row">
              <div class="col-md-3">
                <img
                  src="img/company/Evyty%20Back%20Entry.jpg"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <div class="col-md-9 mt-sm-20">
                <p>
                  Custom made curtains and blinds are also our another line of
                  business to serve our customers.
                </p>
                <p>
                  Evyty is dedicated to make your living and working environment
                  more colourful and beautiful!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLaylout>
  );
}
