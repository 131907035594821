import { MainLaylout } from "../component/MainLayout";
import { HeroHeader } from "../component/HeroHeader";
import { Box } from "@chakra-ui/react";

export function PageNotFound() {
  return (
    <MainLaylout>
      <HeroHeader title={"Page Not Found"} breadcrumb={[]} />

      <div class="whole-wrap">
        <div class="container box_1170">
          <div class="section-top-border">
            <h3 class="mb-30">404</h3>
            <div class="row">
              <Box>Page Not Found</Box>
            </div>
          </div>
        </div>
      </div>
    </MainLaylout>
  );
}
