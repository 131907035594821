import {
  Box,
  HStack,
  Button,
  Image,
  IconButton,
  Modal,
  Heading,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  // CardFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  InputGroup,
  InputRightAddon,
  // Divider,
  VStack,
  // TableContainer,
  // Table,
  // Tr,
  Text,
  Card,
  CardBody,
  Stack,
  Flex,
  Spacer,
  Textarea,
  // AlertDialog,
  // AlertDialogOverlay,
  // AlertDialogHeader,
  // AlertDialogContent,
  // AlertDialogBody,
  // AlertDialogFooter,
  // useToast,
} from "@chakra-ui/react";
import {
  createColor,
  deleteColor,
  updateColor,
} from "../../api_client/admin_client";
import { useEffect, useState, useContext } from "react";
import { IoMdClose } from "react-icons/io";
import { AppContext } from "../../context/AppContext";
import { MdDelete, MdEdit } from "react-icons/md";
import { OperationBar } from "./AdminOperationBar";
import { ConfirmDialog } from "../ConfirmDialog";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../context/ToastContext";
import { SearchableSelect } from "../SearchableSelect";

const ColorCard = ({ color, imgBase, onEdit, onDelete }) => {
  return (
    <Card
      direction={"column"}
      overflow="hidden"
      variant="outline"
      // maxW={"400px"}
      w={"300px"}
      p={2}
      m={2}
    >
      <Box position="relative" align={"center"}>
        <Image
          alignSelf={"center"}
          src={imgBase + "/" + color.IMAGE}
          // objectFit="cover"
          minW={"80px"}
          h={"80px"}
          maxW={{ base: "200px", sm: "200px" }}
        />
        {/* <Checkbox
          colorScheme="red"
          position="absolute"
          top="2"
          left="2"
          bg="rgba(255, 255, 255, 0.7)"
          borderRadius="md"
          p="1"
        /> */}
      </Box>
      <Stack>
        <CardBody>
          <Heading size="md">{color.NAME}</Heading>
          <Text py="2" fontSize={"sm"}>
            {color.DESCRIPTION}
          </Text>
          <HStack>
            <Text fontSize={"sm"}>Category: </Text>
            <Text py="" fontSize={"sm"}>
              {color.CAT_NAME}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize={"sm"}>Sub-Category: </Text>
            <Text py="2" fontSize={"sm"}>
              {color.SUBCAT_NAME}
            </Text>
          </HStack>
          <Flex w={"full"} align={"center"}>
            <VStack alignItems={"left"} textAlign={"left"} justify={"left"}>
              <Flex p={0} justify={"flex-start"} align={"center"}>
                <Text>Visible: {color.VISIBLE ? "Y" : "N"}</Text>
                {/* <Checkbox isChecked={color.VISIBLE} disabled /> */}
              </Flex>
              {/*<Text p={0}>Position Order: {color.POSITION}</Text>*/}
            </VStack>
            <Spacer />
            <Flex px={2}>
              <IconButton
                px={2}
                mx={1}
                icon={<MdEdit />}
                fontSize={"1.2em"}
                colorScheme="red"
                variant={"outline"}
                onClick={() => onEdit(color)}
                aria-label="Edit"
              />
              <IconButton
                px={2}
                mx={1}
                fontSize={"1.2em"}
                icon={<MdDelete />}
                colorScheme="red"
                variant={"outline"}
                onClick={() => onDelete(color)}
                aria-label="Delete"
              />
            </Flex>
          </Flex>
        </CardBody>
        {/* <CardFooter>
          <Button variant="solid" colorScheme="blue">
            Buy Latte
          </Button>
        </CardFooter> */}
      </Stack>
    </Card>
  );
};
const ColorTable = ({ triggerReload, setTriggerReload, searchStr, onEdit }) => {
  const navigate = useNavigate();
  const { showToast } = useContext(ToastContext);
  const { allCategories, catToSubcat, imageBaseUrl, allColors, reloadContext } =
    useContext(AppContext);
  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  // const [colorsInPage, setColorsInPage] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [catId, setCatId] = useState(null);
  const [subcatId, setSubcatId] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure(); //for delete dialog
  // useEffect(() => {
  //   const loadForPage = async () => {
  //     const rsp = await getColors();
  //
  //     // console.debug("getColors: ", rsp);
  //     if (!rsp.success) {
  //       console.error("failed to load colors: ", rsp);
  //     } else {
  //       setColorsInPage(rsp.results);
  //     }
  //   };
  //   loadForPage();
  // }, [triggerReload]);

  useEffect(() => {
    // console.debug("on search string change: ", searchStr);
    let f = allColors.filter((c) =>
      c.NAME.toUpperCase().includes(searchStr.toUpperCase()),
    );
    if (catId === null && subcatId === null) {
      setFiltered(f);
    } else if (catId === null) {
      setFiltered(f);
    } else if (subcatId === null) {
      f = f.filter((c) => c.CAT_ID === catId);
    } else {
      f = f.filter((c) => c.CAT_ID === catId && c.SUBCAT_ID === subcatId);
    }
    setFiltered(f);
  }, [searchStr, allColors, catId, subcatId, triggerReload]);

  const onDeleteColor = (c) => {
    setCurrentItem(c);
    onOpen();
  };
  const handleDelete = async () => {
    console.debug("-----handleDelete---", currentItem);

    const rsp = await deleteColor(currentItem, currentUser);
    if (!rsp.success) {
      showToast(rsp.message, "Login Required", "error");
      updateCurrentUser(null);
      navigate("/login");
    }
    reloadContext();
    setTriggerReload((p) => !p);
    onClose();
  };
  const getSubcatsByCat = () => {
    if (catId) {
      return catToSubcat.get(`${catId}`);
    }
    return [];
  };
  const onCatChange = (id) => {
    setCatId(id);
    const subcats = catToSubcat.get(id);
    setSubcatId(subcats[0].SUBCAT_ID);
  };
  const onSubcatChange = (id) => {
    setSubcatId(id);
  };
  return (
    <VStack>
      <Flex w={"full"} mx={20} justifyContent="space-between">
        <SearchableSelect
          margin={20}
          title={"Category"}
          allData={allCategories}
          getItemName={(item) => (item ? item.NAME : "")}
          getItemID={(item) => (item ? item.ID : null)}
          selectedID={catId}
          selectAll={true}
          onSelectChange={(item) =>
            item ? onCatChange(item.ID) : setCatId(null) && setSubcatId(null)
          }
        />
        <SearchableSelect
          margin={20}
          title={"SubCategory"}
          allData={getSubcatsByCat()}
          getItemName={(item) => (item ? item.SUBCAT_NAME : "")}
          getItemID={(item) => (item ? item.SUBCAT_ID : null)}
          selectedID={subcatId}
          selectAll={true}
          visibleFunc={(item) => (item ? item.SUBCAT_VISIBLE > 0 : true)}
          onSelectChange={(item) =>
            item ? onSubcatChange(item.SUBCAT_ID) : setSubcatId(null)
          }
        />
      </Flex>
      <Flex wrap={"wrap"} w={"full"}>
        {filtered ? (
          filtered.map((c) => (
            <Box position="relative">
              <ColorCard
                color={c}
                key={c.ID}
                imgBase={imageBaseUrl}
                onDelete={onDeleteColor}
                onEdit={onEdit}
              />
              {/*<Checkbox*/}
              {/*  colorScheme="red"*/}
              {/*  position="absolute"*/}
              {/*  top="2"*/}
              {/*  left="2"*/}
              {/*  bg="rgba(255, 255, 255, 0.7)"*/}
              {/*  borderRadius="md"*/}
              {/*  p="2"*/}
              {/*/>*/}
            </Box>
          ))
        ) : (
          <Box>
            <Text color={"gray.800"} fontSize={"md"} align={"center"}>
              Empty
            </Text>
          </Box>
        )}

        <ConfirmDialog
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          item={currentItem}
          info={currentItem ? currentItem.NAME : ""}
          // onRefresh={onRefresh}
          yesAction={handleDelete}
        />
      </Flex>
    </VStack>
  );
};
const NewColorModal = ({
  isOpen,
  onOpen,
  onClose,
  isReset,
  onRefresh,
  color,
}) => {
  const navigate = useNavigate();
  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const { allCategories, catToSubcat, imageBaseUrl, reloadContext } =
    useContext(AppContext);
  const [isVisible, setIsVisible] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [catId, setCatId] = useState(null);
  const [subcatId, setSubcatId] = useState(null);
  // const [sort, setSort] = useState(100);
  const [preview, setPreview] = useState(null);
  const [updateImage, setUpdateImage] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    image: false,
    // sort: false,
    visible: false,
    cat_id: false,
    subcat_id: false,
  });

  useEffect(() => {
    if (isReset) {
      setIsVisible(true);
      setName("");
      setImage("");
      // setSort(100);
      setPreview(null);
      setCatId(null);
      setSubcatId(null);
    }
    if (color) {
      setName(color.NAME);
      setDescription(color.DESCRIPTION ? color.DESCRIPTION : "");
      // setSort(color.POSITION);
      setIsVisible(color.VISIBLE === 1);
      console.debug("imageBaseUrl:", `${imageBaseUrl}/${color.IMAGE}`);
      setPreview(`${imageBaseUrl}/${color.IMAGE}`);
      setCatId(color.CAT_ID);
      setSubcatId(color.SUBCAT_ID);
    }
  }, [isReset, color, imageBaseUrl]);

  const resetImage = () => {
    setImage(null);
    setPreview(null);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.debug("loading file: ", file);
      setImage(file);
      if (color) {
        setUpdateImage(true);
      }
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
      setErrors((pr) => ({ ...pr, image: false }));
    }
  };
  const getSubcatsByCat = () => {
    if (catId) {
      return catToSubcat.get(`${catId}`);
    }
    return [];
  };
  const onCatChange = (id) => {
    setCatId(id);
    const subcats = catToSubcat.get(id);
    setSubcatId(subcats[0].SUBCAT_ID);
  };
  const onSubcatChange = (id) => {
    setSubcatId(id);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const check = {
      ...errors,
      name: !name || name === "",
      image: color ? false : !image || image === null,
      cat_id: !catId || catId === null,
      subcat_id: !subcatId || catId === null,
    };
    const anyError = Object.values(check).reduce((r, c) => r || c, false);
    console.debug("anyError: ", anyError, check);
    if (anyError) {
      setErrors(check);
      return;
    }

    // if (sort < 0) {
    //   setSort(100);
    // }
    // console.debug("creating color: ", name, image, isVisible, sort);
    const formData = new FormData();
    if (color) {
      formData.append("id", color.ID);
      if (updateImage) {
        formData.append("image", image);
      } else {
        let regex = new RegExp(imageBaseUrl + "/", "g");
        let short = preview.replace(regex, "");
        console.debug("preview.replace: ", short);
        formData.append("image_file", color.IMAGE);
      }
    } else {
      formData.append("image", image);
    }
    formData.append("name", name);
    // formData.append("sort", sort);
    formData.append("visible", isVisible);
    formData.append("description", description);
    formData.append("cat_id", catId);
    formData.append("subcat_id", subcatId);
    if (color) {
      const rsp = await updateColor(color.ID, formData, currentUser);
      console.debug("onsubmit updateColor: ", rsp);
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        updateCurrentUser(null);
        navigate("/login");
      }
      reloadContext();
    } else {
      const rsp = await createColor(formData, currentUser);
      console.debug("onsubmit createColor: ", rsp);
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        updateCurrentUser(null);
        navigate("/login");
      }
      reloadContext();
    }
    onRefresh();
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={{ base: "80%", sm: "full" }}>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Create Color</ModalHeader>
          <ModalCloseButton colorScheme="red" />
          <ModalBody>
            <Box>
              <FormControl isInvalid={errors.name} pb={2}>
                <FormLabel>Color Name</FormLabel>
                <Input
                  type="text"
                  name="color_name"
                  placeholder="Color Name"
                  value={name}
                  onChange={(event) => {
                    if (event.target.value !== "") {
                      setErrors((prev) => ({ ...prev, name: false }));
                    }
                    setName(event.target.value);
                  }}
                />
                {/* <FormErrorMessage>Color name is required.</FormErrorMessage> */}
                {errors.name ? (
                  <FormErrorMessage>Color name is required.</FormErrorMessage>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl isInvalid={errors.image} pb={2}>
                <FormLabel>Color Image</FormLabel>
                <InputGroup>
                  <Input
                    type="file"
                    name="color_image"
                    accept="image/*"
                    placeholder="Image File"
                    onChange={handleImageChange}
                  />
                  <InputRightAddon>
                    <IconButton
                      colorScheme="gray"
                      variant={"outline"}
                      icon={<IoMdClose />}
                      onClick={resetImage}
                    />
                  </InputRightAddon>
                </InputGroup>
                {errors.image ? (
                  <FormErrorMessage>Color Image is required.</FormErrorMessage>
                ) : (
                  <></>
                )}
              </FormControl>

              <SearchableSelect
                title={"Category"}
                allData={allCategories}
                getItemName={(item) => (item ? item.NAME : "")}
                getItemID={(item) => (item ? item.ID : null)}
                selectedID={catId}
                onSelectChange={(item) => (item ? onCatChange(item.ID) : null)}
                error={errors.cat_id}
              />
              <SearchableSelect
                title={"SubCategory"}
                allData={getSubcatsByCat()}
                getItemName={(item) => (item ? item.SUBCAT_NAME : "")}
                getItemID={(item) => (item ? item.SUBCAT_ID : null)}
                selectedID={subcatId}
                onSelectChange={(item) =>
                  item ? onSubcatChange(item.SUBCAT_ID) : null
                }
                error={errors.subcat_id}
              />
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  minH={"5"}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </FormControl>
              <HStack>
                <FormControl>
                  <FormLabel>Is Visible</FormLabel>
                  <Checkbox
                    isChecked={isVisible}
                    onChange={(e) => setIsVisible(e.target.checked)}
                  />
                </FormControl>
                {/*<FormControl isRequired>*/}
                {/*  <FormLabel>Position Order</FormLabel>*/}
                {/*  <Input*/}
                {/*    type="number"*/}
                {/*    name="sort"*/}
                {/*    placeholder="Order by position"*/}
                {/*    value={sort}*/}
                {/*    onChange={(e) => setSort(e.target.value)}*/}
                {/*  />*/}
                {/*  {errors.sort && (*/}
                {/*    <FormErrorMessage>*/}
                {/*      Position order is required(default 100).*/}
                {/*    </FormErrorMessage>*/}
                {/*  )}*/}
                {/*</FormControl>*/}
              </HStack>
              {preview && (
                <Image
                  p={2}
                  src={preview}
                  alt="Preview"
                  boxSize="auto"
                  variant="outline"
                />
              )}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="ghost"
              // onClick={handleSubmit}
              colorScheme="red"
              type="submit"
            >
              {color ? "Update" : "Create"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export const AdminColorPanel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [triggerReload, setTriggerReload] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [searchStr, setSearchStr] = useState("");
  const onRefresh = () => {
    setTriggerReload((p) => !p);
  };
  const onEdit = (color) => {
    setCurrentItem(color);
    onOpen();
  };
  const onNew = () => {
    setCurrentItem(null);
    onOpen();
  };
  return (
    <Box w={"full"}>
      <OperationBar
        onNew={onNew}
        onRefresh={onRefresh}
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      />
      <ColorTable
        triggerReload={triggerReload}
        setTriggerReload={setTriggerReload}
        searchStr={searchStr}
        onEdit={onEdit}
      />
      <NewColorModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        isReset={true}
        onRefresh={onRefresh}
        color={currentItem}
      />
    </Box>
  );
};
