import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  // Menu,
  // MenuButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  // Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  // Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useState, useEffect, useContext, useCallback } from "react";
import { AppContext } from "../../context/AppContext";
import { OperationBar } from "./AdminOperationBar";
import { ConfirmDialog } from "../ConfirmDialog";
import { AdminPaginator } from "./AdminPaginator";
import {
  createProduct,
  // createSubcategory,
  deleteProduct,
  searchProducts,
  updateProduct,
  // updateSubcategory,
} from "../../api_client/admin_client";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContext } from "../../context/ToastContext";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { SearchableSelect } from "../SearchableSelect";
import { getColors } from "../../api_client/fetch_client";

const ProductCard = ({ prod, imgBase, onEdit, onDelete, color }) => {
  const onEditProd = (p) => {
    onEdit(p);
  };
  if (color && color.length > 0) {
    console.debug("product card:", color);
  }
  return (
    <Card
      direction={"column"}
      overflow="hidden"
      variant="outline"
      w={"450px"}
      p={2}
      m={2}
    >
      <Box position="relative" justify={"center"} align={"center"}>
        <Image
          alignSelf={"center"}
          src={
            prod.IMAGE ? imgBase + "/" + prod.IMAGE : imgBase + "/no-photo.jpg"
          }
          objectFit="cover"
          minW={"400px"}
          h={"400px"}
          maxW={{ base: "400px", sm: "200px" }}
          borderWidth={1}
          borderColor={"gray.500"}
          borderStyle={"solid"}
        />
      </Box>
      <Stack>
        <CardBody>
          <Heading size="md">{prod.NAME}</Heading>
          <HStack>
            <Text fontSize={"sm"}>Category: </Text>
            <Text py="2" fontSize={"sm"}>
              {prod.CAT_NAME}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize={"sm"}>Sub-Category: </Text>
            <Text py="2" fontSize={"sm"}>
              {prod.SUBCAT_NAME}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize={"sm"}>Color: </Text>
            <Text py="2" fontSize={"sm"}>
              {color && color.length > 0 ? color[0].NAME : ""}
            </Text>
            {color && color.length > 0 && color[0].IMAGE ? (
              <Image
                src={imgBase + "/" + color[0].IMAGE}
                w={"30px"}
                h={"30px"}
              />
            ) : null}
          </HStack>
          <Flex w={"full"} align={"center"}>
            <VStack alignItems={"left"} textAlign={"left"} justify={"left"}>
              <Flex p={0} justify={"flex-start"} align={"center"}>
                <Text>Visible: {prod.VISIBLE ? "Y" : "N"}</Text>
              </Flex>
              <Text p={0}>Position Order: {prod.POSITION}</Text>
            </VStack>
            <Spacer />
            <Flex px={2}>
              <IconButton
                px={2}
                mx={1}
                icon={<MdEdit />}
                fontSize={"1.2em"}
                colorScheme="red"
                variant={"outline"}
                onClick={() => onEditProd(prod)}
                aria-label="Edit"
              />
              <IconButton
                px={2}
                mx={1}
                fontSize={"1.2em"}
                icon={<MdDelete />}
                colorScheme="red"
                variant={"outline"}
                onClick={() => onDelete(prod)}
                aria-label="Delete"
              />
            </Flex>
          </Flex>
        </CardBody>
      </Stack>
    </Card>
  );
};

function ProductTable({ onEdit, searchStr, triggerReload, setTriggerReload }) {
  const pageSize = 100;
  const navigate = useNavigate();
  const { imageBaseUrl, allColors } = useContext(AppContext);
  const { showToast } = useContext(ToastContext);
  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const [currentItem, setCurrentItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure(); //for delete dialog
  const [pageNumber, setPageNumber] = useState(1);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadForPage = async () => {
      const rsp = await searchProducts(
        !searchStr ? "" : searchStr,
        pageSize,
        (pageNumber - 1) * pageSize,
        currentUser
      );
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        updateCurrentUser(null);
        navigate("/login");
      }
      if (rsp.success) {
        setTotal(rsp.total);
        setProducts(rsp.result);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    loadForPage();
  }, [
    triggerReload,
    searchStr,
    pageNumber,
    currentUser,
    navigate,
    showToast,
    updateCurrentUser,
  ]);
  // useEffect(() => {
  //   console.debug("searchStr searchStr: ", searchStr);
  // }, [searchStr]);
  const handleDelete = async () => {
    const rsp = await deleteProduct(currentItem, currentUser);
    if (!rsp.success) {
      showToast(rsp.message, "Login Required", "error");
      updateCurrentUser(null);
      navigate("/login");
    }
    setTriggerReload((p) => !p);
    onClose();
  };
  const onDeleteProd = (p) => {
    // console.debug("onDeleteProd: ", p);
    setCurrentItem(p);
    onOpen();
  };
  return (
    <VStack spacing={2}>
      <AdminPaginator
        total={total}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
      {isLoading ? (
        <Flex wrap={"wrap"} justify="space-even">
          <Spinner size="lg" />
        </Flex>
      ) : (
        <Flex wrap={"wrap"} justify="space-even">
          {products && products.length > 0 ? (
            products.map((product) => (
              <Box position="relative" key={product.ID}>
                <ProductCard
                  prod={product}
                  // key={product.ID}
                  imgBase={imageBaseUrl}
                  onDelete={onDeleteProd}
                  onEdit={onEdit}
                  color={allColors.filter((c) => c.ID === product.COLOR_ID)}
                />
                <Checkbox
                  colorScheme="red"
                  position="absolute"
                  top="2"
                  left="2"
                  bg="rgba(255, 255, 255, 0.7)"
                  borderRadius="md"
                  p="2"
                />
              </Box>
            ))
          ) : (
            <Box p={50}>
              <Text color={"gray.800"} fontSize={"md"} align={"center"}>
                Empty
              </Text>
            </Box>
          )}
          <ConfirmDialog
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            item={currentItem}
            info={currentItem ? currentItem.NAME : ""}
            yesAction={handleDelete}
          />
        </Flex>
      )}
    </VStack>
  );
}

// const modules = {
//   toolbar: [
//     [{ size: [] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ],
//     [{ color: [] }, { background: [] }],
//     [{ font: [] }],
//     [{ align: [] }],
//     ["link"],
//     ["clean"],
//   ],
// };
function NewProductModal({ isOpen, onOpen, onClose, onRefresh, item }) {
  const { allCategories, catToSubcat, imageBaseUrl, allColors } =
    useContext(AppContext);
  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [filteredColors, setFilteredColors] = useState([]);
  const [catId, setCatId] = useState(null);
  const [subcatId, setSubcatId] = useState(null);
  const [colorId, setColorId] = useState(null);
  const [name, setName] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [sort, setSort] = useState(100);
  const [updateImage, setUpdateImage] = useState(false);
  const [errors, setErrors] = useState({
    cat_id: false,
    subcat_id: false,
    name: false,
    image: false,
  });

  const onCatSubcatChange = useCallback(() => {
    if (!catId && !subcatId) {
      setFilteredColors([]);
    } else if (!catId) {
      setFilteredColors(allColors.filter((c) => c.SUBCAT_ID === subcatId));
    } else if (!subcatId) {
      setFilteredColors(allColors.filter((c) => c.CAT_ID === catId));
    } else {
      setFilteredColors(
        allColors.filter((c) => c.CAT_ID === catId && c.SUBCAT_ID === subcatId)
      );
    }
  }, [allColors, catId, subcatId]);
  useEffect(() => {
    onCatSubcatChange();
  }, [allColors, catId, subcatId, onCatSubcatChange]);
  useEffect(() => {
    if (item) {
      setCatId(item.CAT_ID);
      setSubcatId(item.SUBCAT_ID);
      setColorId(item.COLOR_ID);
      setName(item.NAME);
      setSort(item.POSITION);
      setIsVisible(item.VISIBLE === 1);
      setDescription(item.DESCRIPTION ? item.DESCRIPTION : "");
      setPreview(`${imageBaseUrl}/${item.IMAGE}`);
    } else {
      setCatId(null);
      setSubcatId(null);
      setColorId(null);
      setName("");
      setDescription("");
      setImage(null);
      setIsVisible(true);
      setPreview(null);
    }
  }, [item, imageBaseUrl]);
  const resetImage = () => {
    setImage(null);
    setPreview(null);
  };
  const onCatChange = (id) => {
    setCatId(id);
    const subcats = catToSubcat.get(id);
    setSubcatId(subcats[0].SUBCAT_ID);
  };
  const onSubcatChange = (id) => {
    setSubcatId(id);
  };
  const onColorChange = (id) => {
    setColorId(id);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.debug("loading file: ", file);
      setImage(file);
      if (item) {
        setUpdateImage(true);
      }
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
      setErrors((pr) => ({ ...pr, image: false }));
    }
  };
  const no_pic = imageBaseUrl + "/no-photo.jpg";

  const getSubcatsByCat = () => {
    if (catId) {
      return catToSubcat.get(`${catId}`);
    }
    return [];
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const check = {
      ...errors,
      name: !name || name === "",
      image: item ? false : !image,
      cat_id: !catId,
      subcat_id: !subcatId,
    };
    const anyError = Object.values(check).reduce((r, c) => r || c, false);
    console.debug("anyError: ", anyError, catId);
    if (anyError) {
      setErrors(check);
      return;
    }

    const formData = new FormData();
    if (item) {
      formData.append("id", item.ID);
      if (updateImage) {
        formData.append("image", image);
      } else {
        let regex = new RegExp(imageBaseUrl + "/", "g");
        let short = preview.replace(regex, "");
        console.debug("preview.replace: ", short);
        formData.append("image_file", item.IMAGE);
      }
    } else {
      formData.append("image", image);
    }
    formData.append("position", sort);
    formData.append("cat_id", catId);
    formData.append("subcat_id", subcatId);
    formData.append("color_id", colorId);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("visible", isVisible);
    console.debug("before submit: ", formData);
    if (item) {
      const rsp = await updateProduct(item.ID, formData, currentUser);
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        updateCurrentUser(null);
        navigate("/login");
        return;
      }
      console.debug("onsubmit updateSubcategory: ", rsp, formData);
    } else {
      const rsp = await createProduct(formData, currentUser);
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        updateCurrentUser(null);
        navigate("/login");
        return;
      }
      console.debug("onsubmit createProduct: ", rsp);
    }
    onRefresh();
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ base: "100%", sm: "80%" }}>
        <form onSubmit={handleSubmit}>
          <ModalHeader>{item ? "Edit " : "Create "} Product</ModalHeader>
          <ModalCloseButton colorScheme="red" />
          <ModalBody>
            <Flex>
              <Box p={2} w={"50%"}>
                <FormControl isInvalid={errors.name} pb={2}>
                  <FormLabel>Product Name</FormLabel>
                  <Input
                    type="text"
                    name="prod_name"
                    placeholder="Product Name"
                    value={name}
                    onChange={(event) => {
                      if (event.target.value !== "") {
                        setErrors((prev) => ({ ...prev, name: false }));
                      }
                      setName(event.target.value);
                    }}
                  />
                </FormControl>
                <FormControl isInvalid={errors.image} pb={2}>
                  <FormLabel>Product Image</FormLabel>
                  <InputGroup>
                    <Input
                      type="file"
                      name="prod_image"
                      accept="image/*"
                      placeholder="Image File"
                      onChange={handleImageChange}
                    />
                    <InputRightAddon>
                      <IconButton
                        aria-label={"reset"}
                        colorScheme="gray"
                        variant={"outline"}
                        icon={<IoMdClose />}
                        onClick={resetImage}
                      />
                    </InputRightAddon>
                  </InputGroup>
                  {errors.image ? (
                    <FormErrorMessage>
                      Product Image is required.
                    </FormErrorMessage>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <SearchableSelect
                  title={"Category"}
                  allData={allCategories}
                  getItemName={(item) => (item ? item.NAME : "")}
                  getItemID={(item) => (item ? item.ID : null)}
                  selectedID={catId}
                  onSelectChange={(item) =>
                    item ? onCatChange(item.ID) : null
                  }
                />

                <SearchableSelect
                  title={"SubCategory"}
                  allData={getSubcatsByCat()}
                  getItemName={(item) => (item ? item.SUBCAT_NAME : "")}
                  getItemID={(item) => (item ? item.SUBCAT_ID : null)}
                  selectedID={subcatId}
                  onSelectChange={(item) =>
                    item ? onSubcatChange(item.SUBCAT_ID) : null
                  }
                  visibleFunc={(item) => (item ? item.SUBCAT_VISIBLE : true)}
                />
                <SearchableSelect
                  title={"Color"}
                  allData={filteredColors}
                  getItemName={(item) => (item ? item.NAME : "")}
                  getItemID={(item) => (item ? item.ID : null)}
                  selectedID={colorId}
                  onSelectChange={(item) =>
                    item ? onColorChange(item.ID) : null
                  }
                  // visibleFunc={(item) => (item ? item.VISIBLE : true)}
                />

                <HStack>
                  <FormControl>
                    <FormLabel>Is Visible</FormLabel>
                    <Checkbox
                      isChecked={isVisible}
                      onChange={(e) => setIsVisible(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Position Order</FormLabel>
                    <Input
                      type="number"
                      name="sort"
                      placeholder="Order by position"
                      value={sort}
                      onChange={(e) => setSort(e.target.value)}
                    />
                    {errors.sort && (
                      <FormErrorMessage>
                        Position order is required(default 100).
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </HStack>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={(event, editor) =>
                      setDescription(editor.getData())
                    }
                    config={{
                      toolbar: {
                        items: [
                          "undo",
                          "redo",
                          "|",
                          "heading",
                          "|",
                          "fontfamily",
                          "fontsize",
                          "fontColor",
                          "fontBackgroundColor",
                          "|",
                          "bold",
                          "italic",
                          "strikethrough",
                          "subscript",
                          "superscript",
                          "code",
                          "|",
                          "link",
                          // "uploadImage",
                          // "blockQuote",
                          // "codeBlock",
                          "|",
                          "bulletedList",
                          "numberedList",
                          // "todoList",
                          // "outdent",
                          // "indent",
                        ],
                        shouldNotGroupWhenFull: false,
                      },
                    }}
                  />
                  {/*<ReactQuill*/}
                  {/*  value={description}*/}
                  {/*  onChange={setDescription}*/}
                  {/*  modules={modules}*/}
                  {/*  key={item?item.ID:}*/}
                  {/*/>*/}
                  {/*<Textarea*/}
                  {/*  minH={"25"}*/}
                  {/*  value={description}*/}
                  {/*  onChange={(event) => setDescription(event.target.value)}*/}
                  {/*/>*/}
                </FormControl>
                {/*<HStack>*/}
                {/*  <FormControl>*/}
                {/*    <FormLabel>Is Visible</FormLabel>*/}
                {/*    <Checkbox*/}
                {/*      isChecked={isVisible}*/}
                {/*      onChange={(e) => setIsVisible(e.target.checked)}*/}
                {/*    />*/}
                {/*  </FormControl>*/}
                {/*  <FormControl isRequired>*/}
                {/*    <FormLabel>Position Order</FormLabel>*/}
                {/*    <Input*/}
                {/*      type="number"*/}
                {/*      name="sort"*/}
                {/*      placeholder="Order by position"*/}
                {/*      value={sort}*/}
                {/*      onChange={(e) => setSort(e.target.value)}*/}
                {/*    />*/}
                {/*    {errors.sort && (*/}
                {/*      <FormErrorMessage>*/}
                {/*        Position order is required(default 100).*/}
                {/*      </FormErrorMessage>*/}
                {/*    )}*/}
                {/*  </FormControl>*/}
                {/*</HStack>*/}
              </Box>
              <Box
                p={2}
                w={"50%"}
                borderWidth={1}
                borderColor={"gray.800"}
                borderStyle={"solid"}
                align={"center"}
                justify={"center"}
              >
                <Image
                  p={2}
                  src={preview ? preview : no_pic}
                  alt="Preview"
                  boxSize="auto"
                  minH={"400px"}
                  minW={"400px"}
                  align={"center"}
                  justify={"center"}
                  // maxW={"800px"}
                />
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost" colorScheme="red" type="submit">
              {item ? "Update" : "Create"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export const AdminProductPanel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [triggerReload, setTriggerReload] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [searchStr, setSearchStr] = useState(null);
  const [allColors, setAllColors] = useState([]);
  useEffect(() => {
    const loadForPage = async () => {
      const rsp = await getColors();

      // console.debug("getColors: ", rsp);
      if (!rsp.success) {
        console.error("failed to load colors: ", rsp);
      } else {
        setAllColors(rsp.results);
      }
    };
    loadForPage();
  }, []);

  const onRefresh = () => {
    console.debug("onrefresh - search: ", searchStr);
    setTriggerReload((p) => !p);
  };
  const onEdit = (item) => {
    setCurrentItem(item);
    onOpen();
  };
  const onNew = () => {
    setCurrentItem(null);
    onOpen();
  };
  return (
    <Box w={"full"}>
      <OperationBar
        onNew={onNew}
        onRefresh={onRefresh}
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      />
      <ProductTable
        onEdit={onEdit}
        triggerReload={triggerReload}
        setTriggerReload={setTriggerReload}
        searchStr={searchStr}
        allColors={allColors}
      ></ProductTable>
      <NewProductModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onRefresh={onRefresh}
        item={currentItem}
        allColors={allColors}
      />
    </Box>
  );
};
