import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  Image,
  IconButton,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  Card,
  CardBody,
  Stack,
  Heading,
  VStack,
  Spacer,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { OperationBar } from "./AdminOperationBar";
import { AppContext } from "../../context/AppContext";
import { getCategories } from "../../api_client/fetch_client";
import { ConfirmDialog } from "../ConfirmDialog";
import { IoMdClose } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  createCategory,
  deleteCategory,
  updateCategory,
} from "../../api_client/admin_client";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../context/ToastContext";

function NewCategoryModal({ isOpen, onOpen, onClose, onRefresh, item }) {
  const { imageBaseUrl } = useContext(AppContext);
  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [sort, setSort] = useState(100);
  const [preview, setPreview] = useState(null);
  const [updateImage, setUpdateImage] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    image: false,
    sort: false,
    visible: false,
  });

  useEffect(() => {
    if (item) {
      setName(item.NAME);
      setDescription(item.DESCRIPTION ? item.DESCRIPTION : "");
      setSort(item.POSITION);
      setIsVisible(item.VISIBLE === 1);
      setPreview(`${imageBaseUrl}/${item.IMAGE}`);
    }
  }, [item, imageBaseUrl]);

  const resetImage = () => {
    setImage(null);
    setPreview(null);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.debug("loading file: ", file);
      setImage(file);
      if (item) {
        setUpdateImage(true);
      }
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
      setErrors((pr) => ({ ...pr, image: false }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const check = {
      ...errors,
      name: !name || name === "",
      image: item ? false : !image || image === null,
    };
    const anyError = Object.values(check).reduce((r, c) => r || c, false);
    console.debug("anyError: ", anyError);
    if (anyError) {
      setErrors(check);
      return;
    }

    if (sort < 0) {
      setSort(100);
    }
    console.debug("creating color: ", name, image, isVisible, sort);
    const formData = new FormData();
    if (item) {
      formData.append("id", item.ID);
      if (updateImage) {
        formData.append("image", image);
      } else {
        let regex = new RegExp(imageBaseUrl + "/", "g");
        let short = preview.replace(regex, "");
        console.debug("preview.replace: ", short);
        formData.append("image_file", item.IMAGE);
      }
    } else {
      formData.append("image", image);
    }
    formData.append("name", name);
    formData.append("sort", sort);
    formData.append("visible", isVisible);
    formData.append("description", description);
    if (item) {
      const rsp = await updateCategory(item.ID, formData, currentUser);
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        updateCurrentUser(null);
        navigate("/login");
      }
      console.debug("onsubmit updateCategory: ", rsp);
    } else {
      const rsp = await createCategory(formData, currentUser);
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        updateCurrentUser(null);
        navigate("/login");
      }
      console.debug("onsubmit createCategory: ", rsp);
    }
    onRefresh();
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={{ base: "80%", sm: "full" }}>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Create Category</ModalHeader>
          <ModalCloseButton colorScheme="red" />
          <ModalBody>
            <Box>
              <FormControl isInvalid={errors.name} pb={2}>
                <FormLabel>Category Name</FormLabel>
                <Input
                  type="text"
                  name="cat_name"
                  placeholder="Category Name"
                  value={name}
                  onChange={(event) => {
                    if (event.target.value !== "") {
                      setErrors((prev) => ({ ...prev, name: false }));
                    }
                    setName(event.target.value);
                  }}
                />
                {/* <FormErrorMessage>Color name is required.</FormErrorMessage> */}
                {errors.name ? (
                  <FormErrorMessage>
                    Category name is required.
                  </FormErrorMessage>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl isInvalid={errors.image} pb={2}>
                <FormLabel>Category Image</FormLabel>
                <InputGroup>
                  <Input
                    type="file"
                    name="cat_image"
                    accept="image/*"
                    placeholder="Image File"
                    onChange={handleImageChange}
                  />
                  <InputRightAddon>
                    <IconButton
                      aria-label={"reset"}
                      colorScheme="gray"
                      variant={"outline"}
                      icon={<IoMdClose />}
                      onClick={resetImage}
                    />
                  </InputRightAddon>
                </InputGroup>
                {errors.image ? (
                  <FormErrorMessage>Color Image is required.</FormErrorMessage>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  minH={"5"}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </FormControl>
              <HStack>
                <FormControl>
                  <FormLabel>Is Visible</FormLabel>
                  <Checkbox
                    isChecked={isVisible}
                    onChange={(e) => setIsVisible(e.target.checked)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Position Order</FormLabel>
                  <Input
                    type="number"
                    name="sort"
                    placeholder="Order by position"
                    value={sort}
                    onChange={(e) => setSort(e.target.value)}
                  />
                  {errors.sort && (
                    <FormErrorMessage>
                      Position order is required(default 100).
                    </FormErrorMessage>
                  )}
                </FormControl>
              </HStack>
              {preview && (
                <Image
                  p={2}
                  src={preview}
                  alt="Preview"
                  boxSize="auto"
                  variant="outline"
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost" colorScheme="red" type="submit">
              {item ? "Update" : "Create"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

function CatCard({ cat, imgBase, onDelete, onEdit }) {
  const { imageBaseUrl } = useContext(AppContext);
  return (
    <Card
      // maxW={{ base: "100%", sm: "200px" }}
      // direction={{ base: "column", sm: "row" }}
      direction={"column"}
      overflow="hidden"
      variant="outline"
      // maxW={"400px"}
      w={"450px"}
      p={2}
      m={2}
    >
      <Box position="relative" justify={"center"} align={"center"}>
        <Image
          // src={
          //   process.env.NODE_ENV === "development"
          //     ? "/img/logo/logo33.png"
          //     : `${imgBase}/${color.IMAGE}`
          // }
          alignSelf={"center"}
          src={imageBaseUrl + "/" + cat.IMAGE}
          objectFit="cover"
          minW={"400px"}
          h={"400px"}
          maxW={{ base: "400px", sm: "200px" }}
          borderWidth={1}
          borderColor={"gray.500"}
          borderStyle={"solid"}
        />
      </Box>
      <Stack>
        <CardBody>
          <Heading size="md">{cat.NAME}</Heading>
          <Text py="2" fontSize={"sm"}>
            {cat.DESCRIPTION}
          </Text>
          <Flex w={"full"} align={"center"}>
            <VStack alignItems={"left"} textAlign={"left"} justify={"left"}>
              <Flex p={0} justify={"flex-start"} align={"center"}>
                <Text>Visible: {cat.VISIBLE ? "Y" : "N"}</Text>
                {/* <Checkbox isChecked={color.VISIBLE} disabled /> */}
              </Flex>
              <Text p={0}>Position Order: {cat.POSITION}</Text>
            </VStack>
            <Spacer />
            <Flex px={2}>
              <IconButton
                px={2}
                mx={1}
                icon={<MdEdit />}
                fontSize={"1.2em"}
                colorScheme="red"
                variant={"outline"}
                onClick={() => onEdit(cat)}
                aria-label="Edit"
              />
              <IconButton
                px={2}
                mx={1}
                fontSize={"1.2em"}
                icon={<MdDelete />}
                colorScheme="red"
                variant={"outline"}
                onClick={() => onDelete(cat)}
                aria-label="Delete"
              />
            </Flex>
          </Flex>
        </CardBody>
      </Stack>
    </Card>
  );
}

function CategoryTable({ triggerReload, setTriggerReload, onEdit, searchStr }) {
  const { imageBaseUrl } = useContext(AppContext);
  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [catsInPage, setCatsInPage] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure(); //for delete dialog
  useEffect(() => {
    const loadForPage = async () => {
      const rsp = await getCategories();
      console.debug("loadCatsForPage: ", rsp);
      if (rsp.success) {
        setCatsInPage(rsp.results);
      }
    };
    loadForPage();
  }, [triggerReload]);
  useEffect(() => {
    const f = catsInPage.filter((c) =>
      c.NAME.toUpperCase().includes(searchStr.toUpperCase()),
    );
    setFiltered(f);
  }, [catsInPage, searchStr]);
  const handleDelete = async () => {
    const rsp = await deleteCategory(currentItem, currentUser);
    if (!rsp.success) {
      showToast(rsp.message, "Login Required", "error");
      updateCurrentUser(null);
      navigate("/login");
    }
    setTriggerReload((p) => !p);
    onClose();
  };
  const onDeleteCat = (c) => {
    setCurrentItem(c);
    onOpen();
  };
  return (
    <Flex wrap={"wrap"} justify="space-even">
      {filtered || filtered.length === 0 ? (
        filtered.map((c) => (
          <Box position="relative">
            <CatCard
              cat={c}
              key={c.ID}
              imgBase={imageBaseUrl}
              onDelete={onDeleteCat}
              onEdit={onEdit}
            />
            <Checkbox
              colorScheme="red"
              position="absolute"
              top="2"
              left="2"
              bg="rgba(255, 255, 255, 0.7)"
              borderRadius="md"
              p="2"
            />
          </Box>
        ))
      ) : (
        <Box>
          <Text color={"gray.800"} fontSize={"md"} align={"center"}>
            Empty
          </Text>
        </Box>
      )}
      <ConfirmDialog
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        item={currentItem}
        info={currentItem ? currentItem.NAME : ""}
        yesAction={handleDelete}
      />
    </Flex>
  );
}

export const AdminCategoryPanel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [triggerReload, setTriggerReload] = useState(true);
  const [searchStr, setSearchStr] = useState("");
  const [currentItem, setCurrentItem] = useState(null);

  const onRefresh = () => {
    setTriggerReload((p) => !p);
  };
  const onEdit = (item) => {
    setCurrentItem(item);
    onOpen();
  };
  return (
    <Box w={"full"}>
      <OperationBar
        onNew={onOpen}
        onRefresh={onRefresh}
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      />
      <CategoryTable
        onEdit={onEdit}
        triggerReload={triggerReload}
        setTriggerReload={setTriggerReload}
        searchStr={searchStr}
      ></CategoryTable>
      <NewCategoryModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onRefresh={onRefresh}
        item={currentItem}
      />
    </Box>
  );
};
