import { Button, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const PageButton = (props) => {
  const activeStyle = {
    bg: "red.600",
    _dark: {
      bg: "red.500",
    },
    color: "white",
  };
  return (
    <Button
      mx={1}
      px={4}
      py={2}
      M={"50px"}
      rounded="md"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      color="gray.700"
      opacity={props.disabled && 0.6}
      _hover={!props.disabled && activeStyle}
      cursor={props.disabled && "not-allowed"}
      onClick={props.onClick}
      disabled={props.disabled}
      {...(props.active && activeStyle)}
    >
      {props.children}
    </Button>
  );
};

export const AdminPaginator = ({
  total,
  pageSize,
  pageNumber,
  setPageNumber,
}) => {
  const [totalPage, setTotalPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(1);
  useEffect(() => {
    let totalPages = Math.ceil(total / pageSize);
    setTotalPage(totalPages);
    const maxButtons = 10;
    let startPage = Math.max(pageNumber - Math.floor(maxButtons / 2), 1);
    let endPage = startPage + maxButtons - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxButtons + 1, 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    setStart(startPage);
    setEnd(endPage);
    setPages(pageNumbers);
  }, [total, pageSize, pageNumber]);

  const onPageChange = (pn) => {
    console.debug("go to page: ", pn);
    setPageNumber(pn);
  };
  const handlePrevious = () => {
    if (start > 1) {
      setPageNumber(start - 1);
    }
  };
  const handleNext = () => {
    if (end < totalPage) {
      setPageNumber(end + 1);
    }
  };

  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: "#3e3e3e",
      }}
      p={2}
      w="full"
      alignItems="center"
      justifyContent="start"
    >
      <Flex textAlign="center" alignItems={"center"}>
        <PageButton disabled={start === 1} onClick={() => handlePrevious()}>
          {"<"}
        </PageButton>
        {pages.map((p) => (
          <PageButton
            key={p}
            active={p === pageNumber}
            onClick={() => onPageChange(p)}
          >
            {p}
          </PageButton>
        ))}
        <PageButton onClick={() => handleNext()} disabled={end === totalPage}>
          {">"}
        </PageButton>
        <Text mt={4} color="gray.500">
          Found {total} Items
        </Text>
      </Flex>
    </Flex>
  );
};
