import { useEffect, useState, useContext } from "react";
import { getCarousel } from "../api_client/fetch_client";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppContext } from "../context/AppContext";

const CarouselSlider = ({ TEXT1, TEXT2, TEXT3, IMAGE, BTN_TEXT, BTN_LINK }) => {
  const ctx = useContext(AppContext);
  const bg_style = {
    "background-image": `url(${ctx.imageBaseUrl}/${IMAGE})`,
  };
  const border_style = {
    padding: "20px",
    "background-color": "rgba(255, 255, 255, 0.45)",
  };
  return (
    <div
      class="single-slider slider-height d-flex align-items-center"
      style={bg_style}
    >
      <div class="container">
        <div class="rowr" style={border_style}>
          <div class="col-xxl-5 col-xl-6 col-lg-7 col-md-8 col-sm-10">
            <div class="hero-caption text-center">
              <span>{TEXT1}</span>
              <h1 data-animation="bounceIn" data-delay="0.2s">
                {TEXT2}
              </h1>
              <p data-animation="fadeInUp" data-delay="0.4s">
                {TEXT3}
              </p>
              <a
                href={BTN_LINK}
                class="btn_1 hero-btn"
                data-animation="fadeInUp"
                data-delay="0.7s"
              >
                {BTN_TEXT}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const settings = {
  // dots: true,
  arrows: true,
  infinite: true,
  // speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  // autoplaySpeed: 3000,
};
export const Carousel = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const loadCarousel = async () => {
      const rsp = await getCarousel();
      if (!rsp.success) {
        console.error("failed to get carousel");
      } else {
        setData(rsp.results);
      }
    };

    loadCarousel();
  }, []);

  return (
    <section class="slider-area">
      {/* <div class="slider-active"> */}
      <Slider {...settings}>
        {data.map((item) => (
          <CarouselSlider {...item} key={item.ID} />
        ))}
      </Slider>
      {/* </div> */}
    </section>
  );
};
