// components/FileUploader.js
import { useDropzone } from "react-dropzone";
import { useCallback, useContext } from "react";
import { Box, Text } from "@chakra-ui/react";
import { AuthContext } from "../../context/AuthContext";
import { uploadPriceFile } from "../../api_client/admin_client";
import { ToastContext } from "../../context/ToastContext";
const AdminFileUploader = ({ forceRefresh }) => {
  const { showToast } = useContext(ToastContext);
  const { currentUser } = useContext(AuthContext);
  const onDrop = useCallback(
    (acceptedFiles) => {
      const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        const rsp = await uploadPriceFile(formData, currentUser);
        if (rsp && rsp.success) {
          showToast("Success", `${file.name} uploaded`, "success");
          forceRefresh();
        } else {
          showToast("Error", `${rsp.message}: ${file.name}`, "error");
        }
      };
      acceptedFiles.forEach((file) => {
        uploadFile(file);
      });
    },
    [currentUser, showToast, forceRefresh]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true, // Allow multiple file uploads if needed
    accept: {
      "application/pdf": [], // Accept only image files, modify as needed
    },
  });

  return (
    <Box
      {...getRootProps()}
      border="2px dashed"
      borderColor={isDragActive ? "blue.500" : "gray.300"}
      p={6}
      m={6}
      rounded="md"
      textAlign="center"
      cursor="pointer"
      bg={isDragActive ? "blue.50" : "gray.50"}
      _hover={{ bg: "gray.100" }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Text color="blue.600">Drop the PDF files here...</Text>
      ) : (
        <Text color="gray.600">
          Drag & drop PDF files here, or click to select files
        </Text>
      )}
    </Box>
  );
};

export default AdminFileUploader;
