import {
  Image,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
  Center,
} from "@chakra-ui/react";
export function ImageModal({ imgUrl, isOpen, onOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth={"fit-content"}>
        <ModalHeader>IMAGE</ModalHeader>
        <ModalCloseButton color={"red"} />
        <ModalBody>
          <Center>
            <Image src={imgUrl} gap={4} minWidth={800} minHeight={"auto"} />
          </Center>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
