import {
  Flex,
  Box,
  VStack,
  IconButton,
  useDisclosure,
  Spinner,
  Center,
} from "@chakra-ui/react";
import AdminFileUploader from "./AdminFileUploader";
import { useState, useEffect, useContext } from "react";
import { getPriceFiles } from "../../api_client/fetch_client";
import { MdDelete } from "react-icons/md";
import { ConfirmDialog } from "../ConfirmDialog";
import { deletePriceFile } from "../../api_client/admin_client";
import { ToastContext } from "../../context/ToastContext";
import { AuthContext } from "../../context/AuthContext";
export const AdminPricePanel = () => {
  const { showToast } = useContext(ToastContext);
  const { currentUser } = useContext(AuthContext);
  const [priceFiles, setPriceFiles] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure(); //for delete dialog
  useEffect(() => {
    const getData = async () => {
      const all = await getPriceFiles();
      setPriceFiles(all);
    };
    getData();
  }, [refresh]);

  const handleDelete = async () => {
    if (currentItem) {
      const response = await deletePriceFile(currentItem, currentUser);
      console.debug("delete file rsp: ", response);
      if (response && response.success) {
        showToast("Success", `${currentItem.name} deleted`, "success");
        setRefresh((prev) => !prev);
      } else {
        showToast("Error", `${response.message}: ${currentItem.name}`, "error");
      }
      onClose();
    }
  };
  const forceRefresh = () => {
    setRefresh((prev) => !prev);
  };

  return (
    <VStack>
      <Flex w={"full"} dirction={"column"}>
        <AdminFileUploader forceRefresh={forceRefresh} />
      </Flex>
      <Flex wrap={"wrap"} justify={"start"} gap={4} pt={2}>
        {priceFiles && priceFiles.length >= 0 ? (
          priceFiles.map((pf) => (
            <Box position="relative" key={pf.key}>
              <Box
                key={pf.key}
                w="240px" // Adjust the width as needed
                h="100px" // Adjust the height as needed
                p={2}
                mx={2}
                pr={8}
                bg="gray.500"
                alignItems="start"
                justifyContent="start"
                color="white"
                fontSize="md"
                borderRadius="lg"
              >
                {pf.name}
              </Box>
              <IconButton
                px={2}
                mx={1}
                bottom="2"
                right="2"
                position={"absolute"}
                fontSize={"1em"}
                icon={<MdDelete />}
                colorScheme="white"
                variant={"outline"}
                _hover={{ fontSize: "2em" }}
                onClick={() => {
                  setCurrentItem(pf);
                  onOpen();
                }}
                aria-label="Delete"
              />
            </Box>
          ))
        ) : (
          <Center height="50vh">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="red.500"
              size="xl"
            />
          </Center>
        )}
      </Flex>

      <ConfirmDialog
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        item={currentItem}
        info={currentItem ? currentItem.name : ""}
        yesAction={handleDelete}
      />
    </VStack>
  );
};
