import { MainLaylout } from "../component/MainLayout";
import { useContext, useState } from "react";
import { userLogin } from "../api_client/admin_client";
import { AuthContext } from "../context/AuthContext";
import { useToast } from "@chakra-ui/react";

export function Login() {
  const toast = useToast();
  const { updateCurrentUser } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [allowed, setAllowed] = useState(true);
  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAllowed(false);
    // console.debug("onSubmit:", username, password);
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    const rsp = await userLogin(formData);
    // console.debug("onsubmit, userLogin: ", rsp);
    if (rsp.success) {
      updateCurrentUser(rsp.result);
    } else {
      toast({
        title: "Login Failed",
        description: "Username or Password doesn't match",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setAllowed(true);
  };
  return (
    <MainLaylout>
      <div class="login-form-area">
        <form onSubmit={handleSubmit}>
          <div class="login-form">
            <div class="login-heading">
              <span>Admin Login</span>
              <p>Enter Login details to get access</p>
            </div>

            <div class="input-box">
              <div class="single-input-fields">
                <label>Username or Email Address</label>
                <input
                  type="text"
                  placeholder="Username / Email address"
                  value={username}
                  onChange={onUsernameChange}
                  disabled={!allowed}
                />
              </div>
              <div class="single-input-fields">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={onPasswordChange}
                  disabled={!allowed}
                />
              </div>
              <div class="single-input-fields login-check">
                {/*<input*/}
                {/*  type="checkbox"*/}
                {/*  id="fruit1"*/}
                {/*  name="keep-log"*/}
                {/*  disabled={!allowed}*/}
                {/*/>*/}
                {/*<label for="fruit1">Keep me logged in</label>*/}
                {/*<a href="login.html#" class="f-right">*/}
                {/*  Forgot Password?*/}
                {/*</a>*/}
              </div>
            </div>

            <div class="login-footer">
              {/*<p>*/}
              {/*  Don’t have an account? <a href="register.html">Sign Up</a> here*/}
              {/*</p>*/}
              <button class="submit-btn3" type={"submit"} disabled={!allowed}>
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </MainLaylout>
  );
}
