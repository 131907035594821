import { useContext } from "react";
import { AiFillHome } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { Text, Flex, Center } from "@chakra-ui/react";
import { AppContext } from "../context/AppContext";
export function Footer() {
  const { allCategories } = useContext(AppContext);

  return (
    <>
      <footer>
        <div class="footer-wrapper gray-bg">
          <div class="footer-area footer-padding">
            <section class="subscribe-area" style={{ display: "none" }}>
              <div class="container">
                <div class="row justify-content-between subscribe-padding">
                  <div class="col-xxl-3 col-xl-3 col-lg-4">
                    <div class="subscribe-caption">
                      <h3>Subscribe Newsletter</h3>
                      <p>Subscribe newsletter to get 5% on all products.</p>
                    </div>
                  </div>
                  <div class="col-xxl-5 col-xl-6 col-lg-7 col-md-9">
                    <div class="subscribe-caption">
                      <form action="index.html#">
                        <input type="text" placeholder="Enter your email" />
                        <button class="subscribe-btn">Subscribe</button>
                      </form>
                    </div>
                  </div>
                  <div class="col-xxl-2 col-xl-2 col-lg-4">
                    <div class="footer-social">
                      <a href="index.html#">
                        <i class="fab fa-facebook"></i>
                      </a>
                      <a href="index.html#">
                        <i class="fab fa-instagram"></i>
                      </a>
                      <a href="index.html#">
                        <i class="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div class="container">
              <div class="row justify-content-between">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-8">
                  <div class="single-footer-caption mb-50">
                    <div class="single-footer-caption mb-20">
                      <div class="footer-logo mb-35">
                        <a href="index.html">
                          <img src="/img/logo/logo33.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div class="single-footer-caption mb-50">
                    <div class="footer-tittle">
                      <h4>Contact</h4>
                      <ul>
                        {/* <li> */}
                        <Flex gap={4}>
                          <AiFillHome color="white" />
                          <Center>
                            <Text
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              Unit 51, 20 Tucks Road Seven Hills NSW 2147,
                              Australia
                            </Text>
                          </Center>
                        </Flex>
                        {/* </li> */}
                        {/* <li> */}
                        <Flex gap={4}>
                          <BsTelephoneFill color="white" />
                          <Center>
                            <Text
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              02 - 9674 1699 (Australia)
                            </Text>
                          </Center>
                        </Flex>
                        {/* </li> */}
                        {/* <li> */}
                        <Flex gap={4}>
                          <BsTelephoneFill color="white" />
                          <Center>
                            <Text
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              0061 2 9674 1699 (International)
                            </Text>
                          </Center>
                        </Flex>
                        {/* </li> */}
                        {/* <li> */}
                        <Flex gap={4}>
                          <MdEmail color="white" />
                          <Center>
                            <Text
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              info@evyty.com.au
                            </Text>
                          </Center>
                        </Flex>
                        {/* </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div class="single-footer-caption mb-50">
                    <div class="footer-tittle">
                      <h4>Collections</h4>
                      <ul>
                        {allCategories.map((c) => (
                          <li>
                            <a href={`/category/${c.ID}`}>{c.NAME}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                  <div class="single-footer-caption mb-50">
                    <div class="footer-tittle">
                      <h4>Baby Collection</h4>
                      <ul>
                        <li>
                          <a href="index.html#">Clothing Fashion</a>
                        </li>
                        <li>
                          <a href="index.html#">Winter</a>
                        </li>
                        <li>
                          <a href="index.html#">Summer</a>
                        </li>
                        <li>
                          <a href="index.html#">Formal</a>
                        </li>
                        <li>
                          <a href="index.html#">Casual</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                  <div class="single-footer-caption mb-50">
                    <div class="footer-tittle">
                      <h4>Quick Links</h4>
                      <ul>
                        <li>
                          <a href="index.html#">Track Your Order</a>
                        </li>
                        <li>
                          <a href="index.html#">Support</a>
                        </li>
                        <li>
                          <a href="index.html#">FAQ</a>
                        </li>
                        <li>
                          <a href="index.html#">Carrier</a>
                        </li>
                        <li>
                          <a href="index.html#">About</a>
                        </li>
                        <li>
                          <a href="index.html#">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div class="footer-bottom-area">
            <div class="container">
              <div class="footer-border">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="footer-copy-right text-center">
                      <p>Copyright © 2024 Evyty Pty Ltd All rights reserved</p>
                      <p>
                        <a href="/admin">admin</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="back-top" style={{ display: "block" }}>
        <a class="wrapper" title="Go to Top" href="#back-top">
          <div class="arrows-container">
            <div class="arrow arrow-one"></div>
            <div class="arrow arrow-two"></div>
          </div>
        </a>
      </div>
    </>
  );
}
