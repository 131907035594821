import { createContext } from "react";
import { useToast } from "@chakra-ui/react";

export const ToastContext = createContext();
export function ToastContextProvider({ children }) {
  const toast = useToast();

  const showToast = (title, description, schema) => {
    toast({
      title: title,
      description: description,
      status: schema,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <ToastContext.Provider
      value={{
        showToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}
