import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Textarea,
  Text,
  useDisclosure,
  VStack,
  Spacer,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { OperationBar } from "./AdminOperationBar";
import { AppContext } from "../../context/AppContext";
import { IoMdClose } from "react-icons/io";
import {
  getCategories,
  getSubcategoriesWithCategories,
} from "../../api_client/fetch_client";
import { MdDelete, MdEdit } from "react-icons/md";
import { ConfirmDialog } from "../ConfirmDialog";
import {
  createSubcategory,
  deleteSubcategory,
  updateSubcategory,
} from "../../api_client/admin_client";
import { AuthContext } from "../../context/AuthContext";
import { ToastContext } from "../../context/ToastContext";
import { useNavigate } from "react-router-dom";

function SubcategoryTable({
  triggerReload,
  setTriggerReload,
  onEdit,
  searchStr,
}) {
  const { imageBaseUrl } = useContext(AppContext);
  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [subcatsInPageBackup, setSubcatsInPageBackup] = useState([]);
  const [subcatsInPage, setSubcatsInPage] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure(); //for delete dialog
  useEffect(() => {
    const loadForPage = async () => {
      const rsp = await getSubcategoriesWithCategories();
      console.debug("loadsubcatandCatsForPage: ", rsp);
      if (rsp.success) {
        setSubcatsInPageBackup(rsp.results);
      }
    };
    loadForPage();
  }, [triggerReload]);
  useEffect(() => {
    // console.debug("searchStr searchStr: ", searchStr);
    if (subcatsInPageBackup && subcatsInPageBackup.length > 0) {
      if (searchStr) {
        setSubcatsInPage(
          subcatsInPageBackup.filter((c) =>
            c.SUBCAT_NAME.toUpperCase().includes(searchStr.toUpperCase()),
          ),
        );
      } else {
        setSubcatsInPage(subcatsInPageBackup);
      }
    }
  }, [searchStr, subcatsInPageBackup]);

  const handleDelete = async () => {
    const rsp = await deleteSubcategory(currentItem, currentUser);
    if (!rsp.success) {
      showToast(rsp.message, "Login Required", "error");
      updateCurrentUser(null);
      navigate("/login");
    }
    setTriggerReload((p) => !p);
    onClose();
  };
  const onDeleteCat = (c) => {
    setCurrentItem(c);
    onOpen();
  };
  return (
    <Flex wrap={"wrap"} justify="space-even">
      {subcatsInPage || subcatsInPage.length === 0 ? (
        subcatsInPage.map((c) => (
          <Box position="relative">
            <SubcatCard
              subcat={c}
              key={c.SUBCAT_ID}
              imgBase={imageBaseUrl}
              onDelete={onDeleteCat}
              onEdit={onEdit}
            />
            <Checkbox
              colorScheme="red"
              position="absolute"
              top="2"
              left="2"
              bg="rgba(255, 255, 255, 0.7)"
              borderRadius="md"
              p="2"
            />
          </Box>
        ))
      ) : (
        <Box p={50}>
          <Text color={"gray.800"} fontSize={"md"} align={"center"}>
            Empty
          </Text>
        </Box>
      )}
      <ConfirmDialog
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        item={currentItem}
        info={currentItem ? currentItem.SUBCAT_NAME : ""}
        yesAction={handleDelete}
      />
    </Flex>
  );
}

function SubcatCard({ subcat, imgBase, onDelete, onEdit }) {
  const { imageBaseUrl } = useContext(AppContext);
  const onEditSubCat = (sc) => {
    onEdit({
      ID: sc.SUBCAT_ID,
      CAT_ID: sc.CAT_ID,
      NAME: sc.SUBCAT_NAME,
      IMAGE: sc.SUBCAT_IMAGE,
      DESCRIPTION: sc.SUBCAT_DESCRIPTION,
      VISIBLE: sc.SUBCAT_VISIBLE,
      POSITION: sc.SUBCAT_POSITION,
    });
  };
  return (
    <Card
      // maxW={{ base: "100%", sm: "200px" }}
      // direction={{ base: "column", sm: "row" }}
      direction={"column"}
      overflow="hidden"
      variant="outline"
      // maxW={"400px"}
      w={"450px"}
      p={2}
      m={2}
    >
      <Box position="relative" justify={"center"} align={"center"}>
        <Image
          // src={
          //   process.env.NODE_ENV === "development"
          //     ? "/img/logo/logo33.png"
          //     : `${imgBase}/${color.IMAGE}`
          // }
          alignSelf={"center"}
          src={
            subcat.SUBCAT_IMAGE
              ? imageBaseUrl + "/" + subcat.SUBCAT_IMAGE
              : imageBaseUrl + "/no-photo.jpg"
          }
          objectFit="cover"
          minW={"400px"}
          h={"400px"}
          maxW={{ base: "400px", sm: "200px" }}
          borderWidth={1}
          borderColor={"gray.500"}
          borderStyle={"solid"}
        />
      </Box>
      <Stack>
        <CardBody>
          <Heading size="md">{subcat.SUBCAT_NAME}</Heading>
          <Text py="2" fontSize={"sm"}>
            {subcat.CAT_NAME}
          </Text>
          <Flex w={"full"} align={"center"}>
            <VStack alignItems={"left"} textAlign={"left"} justify={"left"}>
              <Flex p={0} justify={"flex-start"} align={"center"}>
                <Text>Visible: {subcat.SUBCAT_VISIBLE ? "Y" : "N"}</Text>
                {/* <Checkbox isChecked={color.VISIBLE} disabled /> */}
              </Flex>
              <Text p={0}>Position Order: {subcat.SUBCAT_POSITION}</Text>
            </VStack>
            <Spacer />
            <Flex px={2}>
              <IconButton
                px={2}
                mx={1}
                icon={<MdEdit />}
                fontSize={"1.2em"}
                colorScheme="red"
                variant={"outline"}
                onClick={() => onEditSubCat(subcat)}
                aria-label="Edit"
              />
              <IconButton
                px={2}
                mx={1}
                fontSize={"1.2em"}
                icon={<MdDelete />}
                colorScheme="red"
                variant={"outline"}
                onClick={() => onDelete(subcat)}
                aria-label="Delete"
              />
            </Flex>
          </Flex>
        </CardBody>
      </Stack>
    </Card>
  );
}

function NewSubcategoryModal({
  isOpen,
  onOpen,
  onClose,
  onRefresh,
  item,
  allCats,
}) {
  const { imageBaseUrl } = useContext(AppContext);
  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [catId, setCatId] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [sort, setSort] = useState(100);
  const [preview, setPreview] = useState(null);
  const [updateImage, setUpdateImage] = useState(false);
  const [errors, setErrors] = useState({
    cat_id: false,
    name: false,
    image: false,
    sort: false,
    visible: false,
  });

  const no_pic = imageBaseUrl + "/no-photo.jpg";

  useEffect(() => {
    if (item) {
      setCatId(item.CAT_ID);
      setName(item.NAME);
      setDescription(item.DESCRIPTION ? item.DESCRIPTION : "");
      setSort(item.POSITION);
      setIsVisible(item.VISIBLE === 1);
      setPreview(`${imageBaseUrl}/${item.IMAGE}`);
    } else {
      setCatId(null);
      setName("");
      setDescription("");
      setSort(100);
      setIsVisible(true);
      setImage(null);
      setPreview(null);
    }
  }, [item, imageBaseUrl]);

  const resetImage = () => {
    setImage(null);
    setPreview(null);
  };
  const onCatChange = (event) => {
    setCatId(event.target.value);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.debug("loading file: ", file);
      setImage(file);
      if (item) {
        setUpdateImage(true);
      }
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
      setErrors((pr) => ({ ...pr, image: false }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const check = {
      ...errors,
      name: !name || name === "",
      image: item ? false : !image || image === null,
      cat_id: !catId,
    };
    const anyError = Object.values(check).reduce((r, c) => r || c, false);
    console.debug("anyError: ", anyError, catId);
    if (anyError) {
      setErrors(check);
      return;
    }

    if (sort < 0) {
      setSort(100);
    }

    const formData = new FormData();
    if (item) {
      formData.append("id", item.ID);
      if (updateImage) {
        formData.append("image", image);
      } else {
        let regex = new RegExp(imageBaseUrl + "/", "g");
        let short = preview.replace(regex, "");
        console.debug("preview.replace: ", short);
        formData.append("image_file", item.IMAGE);
      }
    } else {
      formData.append("image", image);
    }
    formData.append("cat_id", catId);
    formData.append("name", name);
    formData.append("sort", sort);
    formData.append("visible", isVisible);
    formData.append("description", description);
    console.debug("before submit: ", formData);
    if (item) {
      const rsp = await updateSubcategory(item.ID, formData, currentUser);
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        updateCurrentUser(null);
        navigate("/login");
      }
      // console.debug("onsubmit updateSubcategory: ", rsp, formData);
    } else {
      const rsp = await createSubcategory(formData, currentUser);
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        updateCurrentUser(null);
        navigate("/login");
      }
      // console.debug("onsubmit createSubcategory: ", rsp);
    }
    onRefresh();
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ base: "850px", sm: "850px" }}>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Create Sub-Category</ModalHeader>
          <ModalCloseButton colorScheme="red" />
          <ModalBody>
            <Flex>
              <Box minW={"400px"}>
                <FormControl isInvalid={errors.name} pb={2}>
                  <FormLabel>Sub-Category Name</FormLabel>
                  <Input
                    type="text"
                    name="cat_name"
                    placeholder="Sub-Category Name"
                    value={name}
                    onChange={(event) => {
                      if (event.target.value !== "") {
                        setErrors((prev) => ({ ...prev, name: false }));
                      }
                      setName(event.target.value);
                    }}
                  />
                  {/* <FormErrorMessage>Color name is required.</FormErrorMessage> */}
                  {errors.name ? (
                    <FormErrorMessage>
                      Sub-Category name is required.
                    </FormErrorMessage>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.image} pb={2}>
                  <FormLabel>Sub-Category Image</FormLabel>
                  <InputGroup>
                    <Input
                      type="file"
                      name="cat_image"
                      accept="image/*"
                      placeholder="Image File"
                      onChange={handleImageChange}
                    />
                    <InputRightAddon>
                      <IconButton
                        aria-label={"reset"}
                        colorScheme="gray"
                        variant={"outline"}
                        icon={<IoMdClose />}
                        onClick={resetImage}
                      />
                    </InputRightAddon>
                  </InputGroup>
                  {errors.image ? (
                    <FormErrorMessage>
                      Color Image is required.
                    </FormErrorMessage>
                  ) : (
                    <></>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>Category</FormLabel>
                  <Select
                    value={catId}
                    onChange={onCatChange}
                    placeholder="Select Category"
                  >
                    {allCats.map((c) => (
                      <option value={c.ID} key={c.ID}>
                        {c.NAME}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    minH={"5"}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </FormControl>
                <HStack>
                  <FormControl>
                    <FormLabel>Is Visible</FormLabel>
                    <Checkbox
                      isChecked={isVisible}
                      onChange={(e) => setIsVisible(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Position Order</FormLabel>
                    <Input
                      type="number"
                      name="sort"
                      placeholder="Order by position"
                      value={sort}
                      onChange={(e) => setSort(e.target.value)}
                    />
                    {errors.sort && (
                      <FormErrorMessage>
                        Position order is required(default 100).
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </HStack>
              </Box>
              <Box
                borderWidth={1}
                borderColor={"gray.800"}
                borderStyle={"solid"}
                alignContent={"center"}
              >
                <Image
                  p={2}
                  src={preview ? preview : no_pic}
                  alt="Preview"
                  boxSize="auto"
                  // minH={"400px"}
                  minW={"400px"}
                />
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost" colorScheme="red" type="submit">
              {item ? "Update" : "Create"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export const AdminSubcategoryPanel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [triggerReload, setTriggerReload] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [allCats, setAllCats] = useState([]);
  const [searchStr, setSearchStr] = useState(null);

  useEffect(() => {
    const loadCatsForPage = async () => {
      const rsp = await getCategories();
      if (rsp.success) {
        setAllCats(rsp.results);
      }
    };
    loadCatsForPage();
  }, []);

  const onRefresh = () => {
    setTriggerReload((p) => !p);
  };
  const onEdit = (item) => {
    setCurrentItem(item);
    onOpen();
  };
  const onNew = () => {
    setCurrentItem(null);
    onOpen();
  };
  return (
    <Box w={"full"}>
      <OperationBar
        onNew={onNew}
        onRefresh={onRefresh}
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      />
      <SubcategoryTable
        onEdit={onEdit}
        triggerReload={triggerReload}
        setTriggerReload={setTriggerReload}
        searchStr={searchStr}
      ></SubcategoryTable>
      <NewSubcategoryModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onRefresh={onRefresh}
        item={currentItem}
        allCats={allCats}
      />
    </Box>
  );
};
