import { VStack, Box } from "@chakra-ui/react";
import PDFViewer from "../PDFViewer";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

export const TradePricePanel = () => {
  const { imageBaseUrl } = useContext(AppContext);
  const { currentPriceFileKey } = useContext(AppContext);
  return (
    <VStack>
      {currentPriceFileKey && (
        <>
          <Box
            fontSize="lg"
            alignItems="center"
            justifyContent="center"
            p={4}
            m={4}
          >
            {currentPriceFileKey.name}
          </Box>
          <PDFViewer fileUrl={`${imageBaseUrl}/${currentPriceFileKey.key}`} />
        </>
      )}
    </VStack>
  );
};
