import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  // Image,
  Spacer,
  Table,
  // TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Form } from "react-final-form";
import {
  InputControl,
  TableInputControl,
  TableNumberInputControl,
  TextareaControl,
} from "./ContactForm";
import { useContext, useRef, useState } from "react";
import { Turnstile } from "@marsidev/react-turnstile";
import { IoMdAddCircle, IoMdRemoveCircle } from "react-icons/io";
import { addFreeCutting } from "../api_client/fetch_client";
import { ToastContext } from "../context/ToastContext";

const emptyItem = {
  item_no: "",
  color: "",
  quantity: 0,
  remarks: "",
};
const ItemTable = ({ items, setItems }) => {
  const removeItem = (item_id) => {
    if (!items || items.length <= 1) {
      return;
    }
    const newItems = items.filter((item) => item.id !== item_id);
    setItems(newItems);
  };

  const itemOnChange = (item_id, name, value) => {
    let newItems = [];
    for (let i = 0; i < items.length; i++) {
      let tmpItem = { ...items[i] };
      if (items[i].id === item_id) {
        tmpItem[name] = value;
      }
      newItems.push(tmpItem);
    }
    setItems(newItems);
  };

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            {/*<Th>#</Th>*/}
            <Th>ITEM NO</Th>
            <Th>COLOR</Th>
            <Th isNumeric>Quantity</Th>
            <Th>REMARKS</Th>
            <Th>
              <IconButton
                icon={<IoMdRemoveCircle />}
                // onClick={() => setItems([{ id: Date.now(), ...emptyItem }])}
                variant={"outline"}
                colorScheme={"gray"}
                aria-label={"remove_all"}
                size={"sm"}
                disabled={true}
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {items &&
            items.map((item, index) => (
              <Tr key={index}>
                {/*<Td>{item.id}</Td>*/}
                <Td>
                  <TableInputControl
                    item_id={item.id}
                    name={"item_no"}
                    value={item.item_no}
                    required={true}
                    onChange={itemOnChange}
                  />
                </Td>
                <Td>
                  <TableInputControl
                    item_id={item.id}
                    name={"color"}
                    value={item.color}
                    required={true}
                    onChange={itemOnChange}
                  />
                </Td>
                <Td>
                  <TableNumberInputControl
                    item_id={item.id}
                    name={"quantity"}
                    value={item.quantity}
                    required={true}
                    onChange={itemOnChange}
                  />
                </Td>
                <Td>
                  <TableInputControl
                    item_id={item.id}
                    name={"remarks"}
                    value={item.remarks}
                    required={false}
                    onChange={itemOnChange}
                  />
                </Td>
                <Td>
                  <IconButton
                    icon={<IoMdRemoveCircle />}
                    onClick={() => removeItem(item.id)}
                    variant={"outline"}
                    colorScheme={"gray"}
                    aria-label={"remove_item"}
                    size={"sm"}
                  />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
function FormRender({
  handleSubmit,
  form,
  submitting,
  pristine,
  items,
  setItems,
  formRef,
}) {
  const appendItem = () => {
    const cnt = Date.now();
    const newItem = {
      id: cnt + 1,
      ...emptyItem,
    };
    const newItems = [...items, newItem];
    setItems(newItems);
  };
  // console.debug("items in table:", items);
  return (
    <form
      p={4}
      ref={formRef}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="1px 1px 3px rgba(0,0,0,0.3)"
      onSubmit={handleSubmit}
      flex={1}
    >
      <Grid templateColumns="repeat(2, 1fr)" gap={0}>
        <GridItem colSpan={1} px={1}>
          <InputControl name="company" label="COMPANY" />
        </GridItem>
        <GridItem colSpan={1} px={1}>
          <InputControl name="abn" label="ABN" />
        </GridItem>
        {/*row 2*/}
        <GridItem colSpan={1} px={1}>
          <InputControl name="phone" label="PHONE" />
        </GridItem>
        <GridItem colSpan={1} px={1}>
          <InputControl name="order_by" label="ORDER BY" />
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <Text textAlign={"center"} fontSize={"lg"}>
            DELIVER TO
          </Text>
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <InputControl
            name="deliver_company"
            label="COMPANY(If different from above)"
            placeholder="COMPANY"
            required={false}
          />
        </GridItem>

        <GridItem colSpan={1} px={1}>
          <InputControl name="contact_person" label="CONTACT PERSON" />
        </GridItem>
        <GridItem colSpan={1} px={1}>
          <InputControl name="contact_number" label="CONTACT NUMBER" />
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <InputControl name="contact_email" label="EMAIL" />
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <InputControl name="deliver_address" label="DELIVER ADDRESS" />
        </GridItem>
        <GridItem colSpan={2} px={1} w={"full"}>
          <HStack
            align={"center"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text textAlign={"center"} fontSize={"lg"} justify={"center"}>
              ITEMS
            </Text>
            <IconButton
              ml={"20px"}
              variant={"outline"}
              icon={<IoMdAddCircle />}
              onClick={appendItem}
              colorScheme={"gray"}
              fontSize={"lg"}
              aria-label={"add"}
              size={"sm"}
            />
          </HStack>
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <ItemTable items={items} setItems={setItems} />
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <Table />
        </GridItem>
        <GridItem colSpan={2} px={1}>
          <TextareaControl
            name="special_request"
            label="SPECIAL REQUEST"
            isRequired={false}
          />
        </GridItem>
      </Grid>
      <Flex flex={1}>
        <Turnstile siteKey="0x4AAAAAAAeESYGmwSqk-ejv" />
        <Spacer />
        <ButtonGroup
          spacing={4}
          // flex={1}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Button
            isLoading={submitting}
            loadingText="Submitting"
            // variantColor="red"
            // colorScheme="red"
            h={"50px"}
            w={"100px"}
            borderRadius={0}
            type="submit"
            bg={"#ff2020"}
            color={"white"}
          >
            Submit
          </Button>
          <Button
            // variantColor="red"
            colorScheme="red"
            variant="outline"
            h={"50px"}
            w={"100px"}
            onClick={form.reset}
            borderRadius={0}
            isDisabled={submitting || pristine}
          >
            Reset
          </Button>
        </ButtonGroup>
      </Flex>
    </form>
  );
}

export function CuttingForm() {
  const { showToast } = useContext(ToastContext);
  const formRef = useRef(null);
  const [items, setItems] = useState([
    {
      id: 0,
      ...emptyItem,
    },
    {
      id: 1,
      ...emptyItem,
    },
  ]);
  const validate = (values) => {
    const errors = {};

    // console.debug("contact validate value: ", values);
    return errors;
  };
  const onSubmit = async (values) => {
    if (
      formRef.current &&
      formRef.current.querySelector('[name="cf-turnstile-response"]') &&
      formRef.current.querySelector('[name="cf-turnstile-response"]').value
    ) {
      const payload = {
        ...values,
        items: JSON.stringify(items),
        "cf-turnstile-response": formRef.current.querySelector(
          '[name="cf-turnstile-response"]',
        ).value,
      };

      // console.debug("onSubmit payload:", payload);
      const rsp = await addFreeCutting(payload);

      // console.debug("onSubmit response:", rsp);
      if (!rsp || !rsp.success) {
        showToast("Error", rsp.message, "error");
      } else {
        showToast(
          "Success",
          "Thank you for the submission. \n We'll reach you out soon.",
          "success",
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } else {
      showToast("Error", "Verify you are human.", "error");
    }
  };
  return (
    <HStack pl={10} alignItems={"flex-start"}>
      <Box w={{ sm: 600, lg: 1000 }} p={4} m="20px auto">
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <FormRender
              handleSubmit={handleSubmit}
              form={form}
              submitting={submitting}
              pristine={pristine}
              items={items}
              setItems={setItems}
              formRef={formRef}
            />
          )}
        />
      </Box>
    </HStack>
  );
}
