import { Footer } from "./Footer";
import { HeaderAll } from "./Header";
import { useEffect } from "react";

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Failed to load script ${src}`));
    document.body.appendChild(script);
  });
};
const jsFiles = [
  "/js/vendor/modernizr-3.5.0.min.js",
  "/js/vendor/jquery-1.12.4.min.js",
  "/js/popper.min.js",
  "/js/bootstrap.min.js",
  "/js/owl.carousel.min.js",
  "/js/slick.min.js",
  "/js/jquery.slicknav.min.js",
  "/js/wow.min.js",
  "/js/jquery.magnific-popup.js",
  "/js/jquery.nice-select.min.js",
  "/js/jquery.counterup.min.js",
  "/js/waypoints.min.js",
  "/js/price_rangs.js",
  //   "/js/contact.js",
  "/js/jquery.form.js",
  "/js/jquery.validate.min.js",
  "/js/mail-script.js",
  "/js/jquery.ajaxchimp.min.js",
  "/js/plugins.js",
  "/js/main.js",
];
export function MainLaylout({ children }) {
  useEffect(() => {
    async function loadjs() {
      for (let i = 0; i < jsFiles.length; i++) {
        await loadScript(jsFiles[i]);
      }
    }
    loadjs();
    // loadScript("js/slick.min.js")
    //   .then(() => {
    //     console.log("slick.js loaded successfully");
    //     // You can add any additional initialization here if needed
    //     loadScript("js/jquery.slicknav.min.js")
    //       .then(() => {
    //         console.log("jquery.slicknav.min.js loaded successfully");
    //         // You can add any additional initialization here if needed
    //         loadScript("js/main.js")
    //           .then(() => {
    //             console.log("main.js loaded successfully");
    //             // You can add any additional initialization here if needed
    //           })
    //           .catch((err) => {
    //             console.error(err);
    //           });
    //       })
    //       .catch((err) => {
    //         console.error(err);
    //       });
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  }, []);

  return (
    <div>
      <HeaderAll />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
