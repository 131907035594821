// here are latest products in categories

import {
  TabPanel,
  TabPanels,
  TabList,
  Tab,
  Tabs,
  // TabIndicator,
} from "@chakra-ui/react";
import {
  useState,
  useEffect,
  // useContext
} from "react";
import { getNewArrivals } from "../api_client/fetch_client";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { AppContext } from "../context/AppContext";
import { ProductsSlider } from "../component/ProductsSlider";

function NavHeader2({ names }) {
  return (
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="nav-button">
            <nav>
              <div class="nav-tittle">
                {/* <h2>New Arrivals</h2> */}
              </div>
              <TabList class="nav nav-tabs">
                {names.map((name, idx) => (
                  <Tab key={idx}>
                    <div class="nav-link">{name}</div>
                  </Tab>
                ))}
              </TabList>
              {/* <TabIndicator mt="55px" height="2px" bg="#ff2020" /> */}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

function NavPanel2({ prodArrays }) {
  return (
    <div class="container">
      <TabPanels>
        {prodArrays.map((arr, idx) => (
          <TabPanel key={idx} class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <ProductsSlider products={arr} />
          </TabPanel>
        ))}
      </TabPanels>
    </div>
  );
}

const fixed = [
  {
    FIXED: 1,
    URL: '/category/22/127',
    IMAGE: 'G2012-2297.png',
    NAME: 'Crystal Tie-backs'
  },
  {
    FIXED: 1,
    URL: '/category/22/126',
    IMAGE: 'C9030 full colours-large-2.jpg',
    NAME: 'Ribbon tiebacks'
  },
  {
    FIXED: 1,
    URL: '/category/22/128',
    IMAGE: 'demo-1_01-copy.jpg',
    NAME: 'Tiebacks&Tassels'
  },
  {
    FIXED: 1,
    URL: '/category/22/120',
    IMAGE: 'C9845-main photo.jpg',
    NAME: 'Tiebacks & tassels'
  },
  {
    FIXED: 1,
    URL: '/category/22',
    IMAGE: 'C98466d-main photo-large-2.jpg',
    NAME: 'Tiebacks & tassels'
  }
]
export function NewArrivals() {
  const [catData, setCatData] = useState([]);
  const [prodData, setProdData] = useState([]);
  function parseData(originalArray) {
    const seenCats = new Set();
    const uniqueCatsArray = [];
    const CatNamesArray = ['Popular'];
    originalArray.forEach((item) => {
      if (!seenCats.has(item.CAT_ID)) {
        seenCats.add(item.CAT_ID);
        uniqueCatsArray.push(item.CAT_ID);
        CatNamesArray.push(item.CAT_NAME);
      }
    });
    setCatData(CatNamesArray);
    const partitioned2 = uniqueCatsArray.map((cid) => {
      return originalArray.filter((item) => item.CAT_ID === cid);
    });
    const partitioned = [fixed, ...partitioned2]
    setProdData(partitioned);
  }
  useEffect(() => {
    async function loadNewArrivals() {
      const rsp = await getNewArrivals();

      if (!rsp.success) {
        throw Error("getNewArrivals failed");
      }
      //   console.debug(rsp.results);
      parseData(rsp.results);
    }
    loadNewArrivals();
  }, []);

  return (
    <div class="latest-items section-padding fix">
      <Tabs colorScheme="red" defaultIndex={0} isLazy>
        <NavHeader2 names={catData} />
        <NavPanel2 prodArrays={prodData} />
      </Tabs>
    </div>
  );
}
