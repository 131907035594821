export function Testimonial() {
  return (
    <div class="testimonial-area testimonial-padding">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-10 col-md-11">
            <div class="h1-testimonial-active">
              <div class="single-testimonial text-center">
                <div class="testimonial-caption">
                  <div class="testimonial-top-cap">
                    <h2>Customer Testimonial</h2>
                    <p>
                      Everybody is different, which is why we offer styles for
                      every body. Laborum fuga incidunt laboriosam voluptas
                      iure, delectus dignissimos facilis neque nulla earum.
                    </p>
                  </div>

                  <div class="testimonial-founder d-flex align-items-center justify-content-center">
                    <div class="founder-img">
                      <img src="img/gallery/founder-img.png" alt="" />
                    </div>
                    <div class="founder-text">
                      <span>Petey Cruiser</span>
                      <p>Designer</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="single-testimonial text-center">
                <div class="testimonial-caption">
                  <div class="testimonial-top-cap">
                    <h2>Customer Testimonial</h2>
                    <p>
                      Everybody is different, which is why we offer styles for
                      every body. Laborum fuga incidunt laboriosam voluptas
                      iure, delectus dignissimos facilis neque nulla earum.
                    </p>
                  </div>

                  <div class="testimonial-founder d-flex align-items-center justify-content-center">
                    <div class="founder-img">
                      <img src="img/gallery/founder-img.png" alt="" />
                    </div>
                    <div class="founder-text">
                      <span>Petey Cruiser</span>
                      <p>Designer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
