export function UpperFooter() {
  return (
    <div class="categories-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div
              class="single-cat mb-50 wow fadeInUp text-center"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div class="cat-icon">
                <img src="img/icon/services1.svg" alt="" />
              </div>
              <div class="cat-cap">
                <h5>Fast & Free Delivery</h5>
                <p>Free delivery on all orders</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div
              class="single-cat mb-50 wow fadeInUp text-center"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div class="cat-icon">
                <img src="img/icon/services2.svg" alt="" />
              </div>
              <div class="cat-cap">
                <h5>Secure Payment</h5>
                <p>Free delivery on all orders</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div
              class="single-cat mb-50 wow fadeInUp text-center"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div class="cat-icon">
                <img src="img/icon/services3.svg" alt="" />
              </div>
              <div class="cat-cap">
                <h5>Money Back Guarantee</h5>
                <p>Free delivery on all orders</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div
              class="single-cat mb-50 wow fadeInUp text-center"
              data-wow-duration="1s"
              data-wow-delay=".5s"
            >
              <div class="cat-icon">
                <img src="img/icon/services4.svg" alt="" />
              </div>
              <div class="cat-cap">
                <h5>Online Support</h5>
                <p>Free delivery on all orders</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
