import {
  Form,
  // useForm
} from "react-final-form";
import {
  Box,
  // Heading,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  Textarea,
  ButtonGroup,
  Button,
  Flex,
  Image,
  HStack,
  Spacer,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
} from "@chakra-ui/react";
import { useField, Field } from "react-final-form";
import { Turnstile } from "@marsidev/react-turnstile";
import { useContext, useRef } from "react";
import { ToastContext } from "../context/ToastContext";
import { addContact } from "../api_client/fetch_client";

function FormRender({ handleSubmit, form, submitting, pristine, formRef }) {
  return (
    <form
      p={4}
      ref={formRef}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="1px 1px 3px rgba(0,0,0,0.3)"
      onSubmit={handleSubmit}
      flex={1}
    >
      <InputControl name="firstName" label="First Name" />
      <InputControl name="lastName" label="Last Name" />
      <InputControl name="phone" label="Contact Number" />
      <InputControl name="email" label="Email Addresss" />
      <TextareaControl name="content" label="Message" />
      <Flex flex={1}>
        <Turnstile siteKey="0x4AAAAAAAeESYGmwSqk-ejv" />
        {/*<TurnstileControl name={"turnstile-control"} />*/}
        <Spacer />
        <ButtonGroup
          spacing={4}
          // flex={1}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Button
            isLoading={submitting}
            loadingText="Submitting"
            // variantColor="red"
            // colorScheme="red"
            h={"50px"}
            w={"100px"}
            borderRadius={0}
            type="submit"
            bg={"#ff2020"}
            color={"white"}
          >
            Submit
          </Button>
          <Button
            // variantColor="red"
            colorScheme="red"
            variant="outline"
            h={"50px"}
            w={"100px"}
            onClick={form.reset}
            borderRadius={0}
            isDisabled={submitting || pristine}
          >
            Reset
          </Button>
        </ButtonGroup>
      </Flex>
    </form>
  );
}
export const TextareaControl = ({ name, label }) => (
  <Control name={name} my={4}>
    <FormLabel htmlFor={name}>{label}</FormLabel>
    <Field
      name={name}
      component={AdaptedTextarea}
      placeholder={label}
      id={name}
    />
    <Error name={name} />
  </Control>
);
export const AdaptedTextarea = ({ input, meta, ...rest }) => (
  <Textarea
    {...input}
    {...rest}
    isInvalid={meta.error && meta.touched}
    focusBorderColor="#ff2020"
    // isRequired={true}
    h={200}
  />
);

export const TableInputControl = ({
  name,
  item_id,
  value,
  required,
  onChange,
}) => {
  const makeName = (name, item_id) => {
    return name + "_" + item_id.toString();
  };
  // const { input, meta } = useField(makeName(name, item_id));
  return (
    <Control name={makeName(name, item_id)}>
      <Input
        colorScheme="red"
        isRequired={
          required === null || required === undefined ? true : required
        }
        value={value}
        id={makeName(name, item_id)}
        focusBorderColor="#ff2020"
        size={"sm"}
        onChange={(e) => onChange(item_id, name, e.target.value)}
      />
      <Error name={makeName(name, item_id)} small={true} />
    </Control>
  );
};
export const TableNumberInputControl = ({ name, item_id, value, onChange }) => {
  const makeName = (name, item_id) => {
    return name + "_" + item_id.toString();
  };
  // const { input, meta } = useField(makeName(name, item_id));
  return (
    <Control name={makeName(name, item_id)}>
      <NumberInput
        step={1}
        defaultValue={1}
        min={1}
        max={1000}
        size={"sm"}
        value={value}
        onChange={(v) => onChange(item_id, name, v)}
      >
        <NumberInputField
          colorScheme="red"
          isRequired={true}
          id={makeName(name, item_id)}
          focusBorderColor="#ff2020"
          size={"sm"}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Error name={makeName(name, item_id)} small={true} />
    </Control>
  );
};
export const InputControl = ({ name, label, placeholder, required }) => {
  const { input, meta } = useField(name);
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        colorScheme="red"
        isRequired={
          required === null || required === undefined ? true : required
        }
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        focusBorderColor="#ff2020"
        placeholder={placeholder ? placeholder : label}
      />
      <Error name={name} />
    </Control>
  );
};
export const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched },
  } = useField(name, { subscription: { touched: true, error: true } });
  return <FormControl {...rest} isInvalid={error && touched} />;
};
export const ControlTurnstile = ({ name, ...rest }) => {
  return <FormControl {...rest} />;
};

//cf-turnstile-response
export const TurnstileControl = ({ name }) => {
  return (
    <Control name={name} my={4}>
      <Turnstile siteKey={"0x4AAAAAAAeESYGmwSqk-ejv"} />
      <Error name={name} />
    </Control>
  );
};
export const Error = ({ name, small }) => {
  const {
    meta: { error },
  } = useField(name, { subscription: { error: true } });
  return (
    <FormErrorMessage size={!!small ? "sm" : "md"}>{error}</FormErrorMessage>
  );
};

export function ContactForm() {
  const formRef = useRef(null);

  const { showToast } = useContext(ToastContext);
  async function onSubmit(values) {
    // event.preventDefault();
    console.debug("contact onSubmit value: ", values);

    if (
      formRef.current &&
      formRef.current.querySelector('[name="cf-turnstile-response"]') &&
      formRef.current.querySelector('[name="cf-turnstile-response"]').value
    ) {
      const body = {
        ...values,
        "cf-turnstile-response": formRef.current.querySelector(
          '[name="cf-turnstile-response"]',
        ).value,
      };

      const rsp = await addContact(body);
      // console.debug("addContact - rsp:", rsp);
      if (!rsp || !rsp.success) {
        showToast("Error", rsp.message, "error");
      } else {
        showToast(
          "Success",
          "Thank you for the submission. \n We'll reach you out soon.",
          "success",
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } else {
      showToast("Error", "Verify you are human.", "error");
      return;
    }
  }

  function validate(values) {
    const errors = {};
    // console.debug("validate: ", values);
    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (!values.email.includes("@")) {
      errors.email = "Invalid email format";
    }
    if (!values.phone) {
      errors.phone = "Required";
    }
    if (!values.content) {
      errors.content = "Required";
    }
    // if (!values.favoriteColor) {
    //   errors.favoriteColor = "Required";
    // } else if (values.favoriteColor === "#00ff00") {
    //   errors.favoriteColor = "Not green! Gross!";
    // }
    // if (!values.toppings || values.toppings.length < 2) {
    //   errors.toppings = "You need at least two toppings";
    // } else if (values.toppings && values.toppings.length > 3) {
    //   errors.toppings = "No more than three toppings";
    // }
    // if (!values.notes) {
    //   errors.notes = "Required";
    // }
    return errors;
  }
  return (
    <HStack pl={10} alignItems={"flex-start"}>
      <Flex p={4} mt={"20px"}>
        <Image
          src="/img/fabric-left-2.png"
          w={"full"}
          flex={1}
          h="auto"
          objectFit={"cover"}
          objectPosition="top"
        />
      </Flex>
      <Box w={{ sm: 400, lg: 800 }} p={4} m="20px auto">
        {/* <Heading as="h1" size="xl" textAlign="center">
          Contact Form
        </Heading> */}
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <FormRender
              handleSubmit={handleSubmit}
              form={form}
              submitting={submitting}
              pristine={pristine}
              formRef={formRef}
            />
          )}
        />
      </Box>
    </HStack>
  );
}
