import { Outlet, useParams } from "react-router-dom";
import { MainLaylout } from "../component/MainLayout";
import { useEffect, useState, useContext } from "react";
import { HeroHeader } from "../component/HeroHeader";
import { AppContext } from "../context/AppContext";
import { Link as RouteLink } from "react-router-dom";
import { useDisclosure, Button } from "@chakra-ui/react";
import {
  Flex,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  VStack,
} from "@chakra-ui/react";

function CategoryMenu({ allCategories, catToSubcat, onToggle }) {
  // const { catid, subcatid } = selectedIds;
  const { viewCatId, viewSubcatId } = useContext(AppContext);
  const [currentIndex, setCurrentIndex] = useState([]);
  useEffect(() => {
    let idx = allCategories.findIndex((c) => `${c.ID}` === viewCatId);
    if (idx < 0) {
      idx = 0;
    }
    setCurrentIndex((c) => [idx]);
  }, [allCategories, viewCatId]);

  function updateIndex(idx) {
    const found = currentIndex.filter((v) => v === idx);
    if (found.length > 0) {
      setCurrentIndex((c) => c.filter((v) => v !== idx));
    } else {
      setCurrentIndex((c) => [...currentIndex, idx]);
    }
  }
  function getCurrentIndex() {
    console.debug("getCurrentIndex:", currentIndex);
    return currentIndex;
  }

  return (
    <Box w={{ base: "100%", md: "100%" }} p={4} bg="gray.200" my={4}>
      <Accordion allowToggle allowMultiple index={getCurrentIndex()}>
        {allCategories.map((c, cindex) => (
          <AccordionItem key={cindex} isFocusable>
            <AccordionButton
              _expanded={{ bg: "#ff2020", color: "white" }}
              color={"black"}
            >
              <Box
                flex="1"
                textAlign="left"
                onClick={() => updateIndex(cindex)}
              >
                {c.NAME}
              </Box>
              <AccordionIcon onClick={() => updateIndex(cindex)} />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <VStack align="start" pl={10}>
                {catToSubcat
                  .get(`${c.ID}`)
                  .filter((c) => c.SUBCAT_VISIBLE > 0)
                  .sort((a, b) => a.SUBCAT_POSITION - b.SUBCAT_POSITION)
                  .map((sc, scidx) => (
                    <Box
                      key={scidx}
                      flex={1}
                      w="full"
                      p={2}
                      bg={
                        `${sc.SUBCAT_ID}` === viewSubcatId
                          ? "#ff2020"
                          : "gray.150"
                      }
                      color={
                        `${sc.SUBCAT_ID}` === viewSubcatId ? "white" : "black"
                      }
                    >
                      <RouteLink
                        w="full"
                        flex={1}
                        to={`/category/${c.ID}/${sc.SUBCAT_ID}`}
                        onClick={onToggle}
                      >
                        <Box
                          w="full"
                          flex={1}
                          _hover={{
                            color:
                              `${sc.SUBCAT_ID}` === viewSubcatId
                                ? "white"
                                : "black",
                            cursor: "pointer",
                            borderWidth:
                              `${sc.SUBCAT_ID}` === viewSubcatId ? "0" : "1",
                            borderStyle: "solid",
                            borderBolor: "#ff2020",
                          }}
                        >
                          {sc.SUBCAT_NAME}
                        </Box>
                      </RouteLink>
                    </Box>
                  ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}

function CategoryLayout({ allCategories, catToSubcat }) {
  // const { catid, subcatid } = selectedIds;
  const { isOpen, onToggle } = useDisclosure();
  // const categoryMenuFlex = useBreakpointValue({ base: 0, lg: 2 });
  // const menuBtn = useBreakpointValue({ base: "block", lg: "none" });

  return (
    <Flex
      bg={"gray.200"}
      // position={{ base: "relative", xl: "" }}
    >
      <Box
        // position={{ base: "absolute", xl: "" }} // Position the CategoryMenu absolutely
        top={0} // Align it to the top
        left={0} // Align it to the left
        width={{ base: "300px", md: "400px", lg:"400px",xl: "400px" }}
        // zIndex={1} // Ensure it appears above the Box content
        bg="gray.200"
        boxShadow="md" // Optional shadow for better visibility
        p={4} // Padding for the CategoryMenu
        display={isOpen || { base: "none", lg: "block" }}
      >
        <CategoryMenu
          allCategories={allCategories}
          catToSubcat={catToSubcat}
          onToggle={onToggle}
        />
      </Box>
      <Box
        flex={1}
        p={4}
        bg="gray.100"
        display={!isOpen || { base: "none", lg: "block" }}
      >
        <Button
          onClick={onToggle}
          display={{ base: "block", lg: "none" }}
          mb={2}
        >
          {isOpen ? "Hide Menu" : "Show Menu"}
        </Button>
        <Outlet />
      </Box>
    </Flex>
  );
}

export function CategoryWrap() {
  const {
    allCategories,
    catToSubcat,
    subcatToCat,
    setViewCatId,
    setViewSubcatId,
    // viewCatId,
    // viewSubcatId,
  } = useContext(AppContext);
  const [breadcrumb, setBreadCrumb] = useState([]);
  // const [catId, setCatId] = useState(null);
  // const [subcatId, setSubcatId] = useState(null);
  const param = useParams();
  console.debug("parm:", param);

  useEffect(() => {
    let crumb = [{ link: "/", name: "Home" }];
    let defaultCatId = param.catid;
    if (defaultCatId === undefined) {
      console.debug("allCategories --> : ", allCategories);
      if (allCategories.length > 0) {
        defaultCatId = `${allCategories[0].ID}`;
      }
    }
    setViewCatId(defaultCatId);
    const _cats = catToSubcat.get(defaultCatId);
    if (_cats !== undefined && _cats.length > 0) {
      crumb.push({
        link: `/category/${defaultCatId}`,
        name: _cats[0].CAT_NAME,
      });
    }
    // if (param.catid !== undefined) {
    //   setCatId(param.catid);
    //   const _cats = catToSubcat.get(param.catid);
    //   if (_cats !== undefined && _cats.length > 0) {
    //     crumb.push({
    //       link: `/category/${param.catid}`,
    //       name: _cats[0].CAT_NAME,
    //     });
    //   }
    // } else {
    //   setCatId(allCategories[0].ID);
    // }
    let defaultSubcatId = param.subcatid;
    if (defaultSubcatId === undefined) {
      const _cats = catToSubcat.get(defaultCatId);
      if (_cats !== undefined && _cats.length > 0) {
        const visibleSubcat = catToSubcat
          .get(defaultCatId)
          .filter((c) => c.SUBCAT_VISIBLE > 0)
          .sort((a, b) => a.SUBCAT_POSITION - b.SUBCAT_POSITION);
        defaultSubcatId = `${visibleSubcat[0].SUBCAT_ID}`;
      }
    }
    setViewSubcatId(defaultSubcatId);
    const _subcats = subcatToCat.get(defaultSubcatId);
    if (_subcats !== undefined && _subcats.length > 0) {
      crumb.push({
        link: `/category/${defaultCatId}/${defaultSubcatId}`,
        name: _subcats[0].SUBCAT_NAME,
      });
    }

    // if (param.subcatid !== undefined) {
    //   setSubcatId(param.subcatid);
    //   const _subcats = subcatToCat.get(param.subcatid);
    //   if (_subcats !== undefined && _subcats.length > 0) {
    //     crumb.push({
    //       link: `/category/${param.catid}/${param.subcatid}`,
    //       name: _subcats[0].SUBCAT_NAME,
    //     });
    //   }
    // } else {
    //   setSubcatId();
    // }

    setBreadCrumb(crumb);
  }, [
    param,
    catToSubcat,
    subcatToCat,
    allCategories,
    setViewCatId,
    setViewSubcatId,
  ]);

  return (
    <>
      <HeroHeader title={"Product In Category"} breadcrumb={breadcrumb} />
      <div class="container">
        <CategoryLayout
          allCategories={allCategories}
          catToSubcat={catToSubcat}
          // selectedIds={{ catid: viewCatId, subcatid: viewSubcatId }}
        />
      </div>
    </>
  );
}

export function Category() {
  return (
    <MainLaylout>
      <CategoryWrap />
    </MainLaylout>
  );
}
