import { MainLaylout } from "../component/MainLayout";
import {
  Card,
  CardHeader,
  CardBody,
  Text,
  // Divider,
  Box,
} from "@chakra-ui/react";

const data = [
  {
    Q: "Q1. Do your products look as good as those shown in the photos?",
    A: "Yes, they do. As a matter of fact, they look only better than shown in the photos. With appropriate usage, they will make your living and working environment colourful and beautiful.",
  },
  {
    Q: "Q2. How long does it take for you to deliver your goods to me after the order is confirmed?",
    A: "It depends on the destination. Most of the time, you will receive your order within a week. But it takes about 5 -10 working days for you to receive your order for example if it is in WA etc .",
  },
  {
    Q: "Q3. What payment options do you offer?",
    A: "We accept direct deposit, Mastercard and Visa card ( credit card payment can be made over the phone)",
  },
  {
    Q: "Q4. How can we meet you and check out your products besides visiting your company?",
    A: "You can contact us either by telephone or email. You can also get on our website to check our products. You can also send us piece of color swatch and we will match for you  ",
  },
  {
    Q: "Q5. Do you offer custom made products?",
    A: "We do to some degree. Custom made products/colours are subject to certain quantity. Normally it takes about 4 to 8 weeks for delivery.You may browse our Products pages to check them out. Some of our products offer a few options in certain features. We are, however, always try to serve your special needs.You are welcome to contact us if you need any assistance in finding or specifying the right products for you.",
  },
  {
    Q: "Q6. Do your Trimmings match your fabrics?",
    A: "Yes, our Jacquard fabrics Bilinga, Passionwave and Blossom collections are matching our trimmings in 23 designs and 26 colours.",
  },
  {
    Q: "Q7. Do you do custom made curtains, blinds etc?",
    A: "Yes, we do. We do Custom-Made Curtains, Swags & Tails, Cushions, Runners, All kinds of Blinds and Pelmets etc.",
  },
  {
    Q: "Q8.  Do you sell your products to other countries apart from Australia?",
    A: "Yes, we do. We sell to America, Japan, New Zealand etc....",
  },
];
export function Faq() {
  return (
    <MainLaylout>
      <div class="hero-area section-bg2">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="slider-area">
                <div class="slider-height2 slider-bg4 d-flex align-items-center justify-content-center">
                  <div class="hero-caption hero-caption2">
                    <h2>F.A.Q</h2>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb justify-content-center">
                        <li class="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li class="breadcrumb-item">
                          <a href="/faq">FAQ</a>
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="whole-wrap">
        <div class="container box_1170">
          <div class="section-top-border">
            <div class="row justify-content-center">
              <div class="col-md-9">
                <h5>
                  In the following, Q stands for Question and A stands for
                  Answer. If you can not find the questions you have in mind,
                  please feel free to contact us.
                </h5>
              </div>
            </div>
            {data.map((d) => (
              <>
                <div class="row justify-content-center">
                  <div class="col-md-9">
                    <Card variant="elevated">
                      <CardHeader>
                        <h4 class="mb-20">{d.Q}</h4>
                        {/* <Divider /> */}
                      </CardHeader>
                      <CardBody>
                        <Text>{d.A}</Text>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <Box h={10} />
              </>
            ))}
          </div>
        </div>
      </div>
    </MainLaylout>
  );
}
