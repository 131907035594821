import { useEffect, useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { SingleProduct } from "./ProductsSlider";
import { getProductsBySubcatId } from "../api_client/fetch_client";

const ColorTile = ({ color, imageBase, selected, onPick }) => {
  return (
    <Box onClick={() => onPick(color.ID)} px={2} cursor="pointer">
      <VStack>
        <Image
          src={
            color.IMAGE
              ? imageBase + "/" + color.IMAGE
              : imageBase + "/no-photo.jpg"
          }
          w={10}
          h={10}
          borderWidth={selected ? 2 : 0}
          borderColor={"red"}
          borderStyle={"solid"}
          borderRadius={selected ? 10 : 20}
        />
        <Text size={"sm"} fontSize={"0.8em"} color={selected ? "red" : "black"}>
          {color.NAME}
        </Text>
      </VStack>
    </Box>
  );
};
const ColorPickerPanel = ({ colors, selectedColor, setSelectedColor }) => {
  const { imageBaseUrl } = useContext(AppContext);

  return (
    <Flex wrap={"wrap"} w={"full"}>
      {colors &&
        colors.map((color) => (
          <ColorTile
            px={2}
            color={color}
            key={color.NAME + `${color.ID}`}
            imageBase={imageBaseUrl}
            selected={color.ID === selectedColor}
            onPick={setSelectedColor}
          />
        ))}
    </Flex>
  );
};
export function ProductsPanel() {
  const { viewCatId, viewSubcatId, allColors } = useContext(AppContext);
  // const [param, setParam] = useState({});
  // const p = useParams();
  // console.debug("ProductsPanel: ", p);
  const [productsData, setProductsData] = useState([]);
  const [colorProductsData, setColorProductsData] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [filteredColors, setFilteredColors] = useState([]);
  useEffect(() => {
    async function loadProductsAll(catid, subcatid) {
      if (subcatid !== null && subcatid !== undefined) {
        const rsp = await getProductsBySubcatId(subcatid);
        if (!rsp.success) {
          throw new Error("getProductsBySubcatId failed");
        }
        setProductsData(rsp.results);
        const colors = allColors
          .filter(
            (c) =>
              `${c.SUBCAT_ID}` === subcatid &&
              `${c.CAT_ID}` === catid &&
              c.CAT_VISIBLE > 0 &&
              c.SUBCAT_VISIBLE > 0 &&
              c.VISIBLE > 0
          )
          .sort((a, b) => a.POSITION - b.POSITION);
        console.debug("all colors2: ", colors);
        setFilteredColors(colors);
        if (colors && colors.length > 0) {
          setSelectedColor(colors[0].ID);
        } else {
          setSelectedColor(null);
        }
      }
    }
    loadProductsAll(viewCatId, viewSubcatId);
  }, [viewCatId, viewSubcatId, allColors]);
  useEffect(() => {
    console.debug(
      "filter prod: ",
      productsData.length,
      selectedColor,
      productsData[0]
    );
    if (selectedColor) {
      const products = productsData
        .filter((p) => p.COLOR_ID === selectedColor)
        .sort((a, b) => a.POSITION - b.POSITION);
      console.debug("color updated: ", products);
      setColorProductsData(products);
    } else {
      setColorProductsData(productsData);
    }
  }, [productsData, selectedColor]);
  return (
    <Box class="products-panel">
      <VStack>
        <ColorPickerPanel
          colors={filteredColors}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
        />
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={6}
        >
          {colorProductsData.map((p, idx) => (
            <GridItem key={p.ID}>
              <SingleProduct product={p} />
            </GridItem>
          ))}
        </Grid>
      </VStack>
    </Box>
  );
}
