import {useRef} from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter, AlertDialogHeader,
    AlertDialogOverlay, Button
} from "@chakra-ui/react";

export const ConfirmDialog = ({ isOpen, onOpen, onClose, info, item, yesAction }) => {
    const cancelRef = useRef();
    return (
        <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={cancelRef}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>Confirmation</AlertDialogHeader>
                    <AlertDialogBody>
                        Are you sure you want to delete it with name:"
                        {info}"?
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            colorScheme="red"
                            ref={cancelRef}
                            onClick={onClose}
                            px={2}
                            mx={1}
                        >
                            No
                        </Button>
                        <Button
                            px={2}
                            mx={1}
                            colorScheme="red"
                            variant={"outline"}
                            onClick={() => yesAction(item)}
                        >
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};