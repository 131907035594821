import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  redirect,
} from "react-router-dom";
import { Home } from "./page/Home";
import { AboutUs } from "./page/AboutUs";
import { ChakraProvider } from "@chakra-ui/react";
import { Faq } from "./page/Faq";
import { FreeCutting } from "./page/FreeCutting";
import { Category } from "./page/Categories";
import { ContactUs } from "./page/ContactUs";
import { Product } from "./page/Product";
import { AppContextProvider } from "./context/AppContext";
import { ProductsPanel } from "./component/ProductsPanel";
import { Login } from "./page/Login";
import { AdminPage } from "./page/admin/AdminPage";
import { TradePage } from "./page/admin/TradePage";
import { AdminColorPanel } from "./component/admin/AdminColorPanel";
import { AdminCategoryPanel } from "./component/admin/AdminCategoryPanel";
import { AdminSubcategoryPanel } from "./component/admin/AdminSubcategoryPanel";
import { AdminProductPanel } from "./component/admin/AdminProductPanel";
import { AdminCarouselPanel } from "./component/admin/AdminCarouselPanel";
import customTheme from "./theme";
import { AuthContext, AuthContextProvider } from "./context/AuthContext";
import { useContext } from "react";
import { ToastContextProvider } from "./context/ToastContext";
import { TradeLogin } from "./page/TradeLogin";
import { TradePricePanel } from "./component/trade/TradePricePanel";
import { PageNotFound } from "./page/PageNotFound";
import { AdminUserPanel } from "./component/admin/AdminUserPanel";
import { AdminTradeUserPanel } from "./component/admin/AdminTradeUserPanel";
import { AdminPricePanel } from "./component/admin/AdminPricePanel";

const RouterMain = () => {
  const { currentUser, currentTradeUser } = useContext(AuthContext);
  const tradeLoginRequired = async () => {
    if (!currentTradeUser) {
      throw redirect("/trade_login");
    }
    return null;
  };
  const loginRequired = async () => {
    if (!currentUser) {
      throw redirect("/login");
    }
    return null;
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <AboutUs />,
    },
    {
      path: "/login",
      loader: async () => {
        if (currentUser) {
          throw redirect("/admin");
        }
        return null;
      },
      element: <Login />,
    },
    {
      path: "/trade_login",
      loader: async () => {
        if (currentTradeUser) {
          throw redirect("/trade");
        }
        return null;
      },
      element: <TradeLogin />,
    },
    {
      path: "/cart",
      element: <Login />,
    },
    {
      path: "/faq",
      element: <Faq />,
    },
    {
      path: "/free_cutting",
      element: <FreeCutting />,
    },
    {
      path: "/trade",
      element: <TradePage />,
      children: [
        {
          index: true,
          loader: tradeLoginRequired,
          element: <Navigate to="/trade/price" replace={true} />,
        },
        {
          path: "price",
          loader: tradeLoginRequired,
          element: <TradePricePanel />,
        },
      ],
    },
    {
      path: "/admin",
      element: <AdminPage />,
      children: [
        {
          index: true,
          loader: loginRequired,
          element: <Navigate to="/admin/color" replace={true} />,
        },
        {
          path: "color",
          loader: loginRequired,
          element: <AdminColorPanel />,
        },
        {
          path: "category",
          loader: loginRequired,
          element: <AdminCategoryPanel />,
        },
        {
          path: "subcategory",
          loader: loginRequired,
          element: <AdminSubcategoryPanel />,
        },
        {
          path: "product",
          loader: loginRequired,
          element: <AdminProductPanel />,
        },
        {
          path: "carousel",
          loader: loginRequired,
          element: <AdminCarouselPanel />,
        },
        {
          path: "user",
          loader: loginRequired,
          element: <AdminUserPanel />,
        },
        {
          path: "trade_user",
          loader: loginRequired,
          element: <AdminTradeUserPanel />,
        },
        {
          path: "prices",
          loader: loginRequired,
          element: <AdminPricePanel />,
        },
      ],
    },
    {
      path: "/category",
      element: <Category />,
      children: [
        {
          index: true,
          element: <ProductsPanel />,
        },
        {
          path: ":catid",
          element: <ProductsPanel />,
          children: [
            {
              path: ":subcatid",
              element: <ProductsPanel />,
            },
          ],
        },
      ],
    },
    {
      path: "/product/:prdid",
      element: <Product />,
    },
    {
      path: "/contact",
      element: <ContactUs />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);
  return <RouterProvider router={router} />;
};
function App() {
  return (
    <AppContextProvider>
      <AuthContextProvider>
        <ToastContextProvider>
          <ChakraProvider theme={customTheme}>
            <RouterMain></RouterMain>
          </ChakraProvider>
        </ToastContextProvider>
      </AuthContextProvider>
    </AppContextProvider>
  );
}

export default App;
