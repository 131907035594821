import { Carousel } from "../component/Carousel";
import { Feature } from "../component/Feature";
import { MainLaylout } from "../component/MainLayout";
import { NewArrivals } from "../component/NewArrivals";
import { Testimonial } from "../component/Testimonial";
import { UpperFooter } from "../component/UpperFooter";

export function Home() {
  return (
    <MainLaylout>
      <Carousel />
      <Feature />
      <NewArrivals />
      <Testimonial />
      <UpperFooter />
    </MainLaylout>
  );
}
