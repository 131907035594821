import { MainLaylout } from "../component/MainLayout";
import { HeroHeader } from "../component/HeroHeader";
import { ContactForm } from "../component/ContactForm";

export function ContactUs() {
  return (
    <MainLaylout>
      <HeroHeader
        title={"Contact Us"}
        breadcrumb={[
          { link: "/", name: "Home" },
          { link: "/contact", name: "Contact Us" },
        ]}
      />
      <div class="whole-wrap">
        <div class="container box_1170">
          <ContactForm />
        </div>
      </div>
    </MainLaylout>
  );
}
