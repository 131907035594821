import { useState, useEffect } from "react";
import { ProductsSlider } from "../component/ProductsSlider";
import { useNavigate } from "react-router-dom";
import {
  getProductsByCatId,
  getProductsBySubcatId,
} from "../api_client/fetch_client";

export function YouMayLike({ CAT_ID, SUBCAT_ID }) {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  console.debug("YouMayLike,", CAT_ID, SUBCAT_ID);
  useEffect(() => {
    async function loadProductsByCatId(cat_id, subcat_id) {
      let rsp = null;
      console.debug("loadProductsByCatId: ", cat_id);
      if (subcat_id === null || subcat_id === undefined) {
        rsp = await getProductsByCatId(cat_id);
      } else {
        rsp = await getProductsBySubcatId(subcat_id);
      }
      if (rsp === null || !rsp.success) {
        console.debug("getProductByCatId in youmaylike failed");
        navigate("/");
      }
      console.debug("get all :", rsp.results);
      let arr = rsp.results;

      arr = arr.sort(() => Math.random() - 0.5);
      if (arr.length > 10) {
        arr = arr.slice(0, 9);
      }
      setProducts(arr);
    }
    loadProductsByCatId(CAT_ID, SUBCAT_ID);
  }, [CAT_ID, SUBCAT_ID, navigate]);
  return (
    <div class="latest-items section-padding fix">
      <div class="row">
        <div class="col-xl-12">
          <div class="section-tittle text-center mb-40">
            <h2>You May Like</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <ProductsSlider products={products} />
      </div>
    </div>
  );
}
