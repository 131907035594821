import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();
export function AuthContextProvider({ children }) {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentTradeUser, setCurrentTradeUser] = useState(null);
  // const [token, setToken] = useState(null);

  useEffect(() => {
    const u = localStorage.getItem("current_user");
    if (u !== null && u !== undefined) {
      const uj = JSON.parse(u);
      // console.debug("localStorage : ", uj);
      setCurrentUser(uj);
    }
    const tu = localStorage.getItem("current_trade_user");
    if (tu !== null && tu !== undefined) {
      const tuj = JSON.parse(tu);
      setCurrentTradeUser(tuj);
    }
  }, []);

  const updateCurrentUser = (u) => {
    console.debug("updateCurrentUser: ", u);
    if (!u) {
      setCurrentUser(null);
      localStorage.removeItem("current_user");
    } else {
      localStorage.setItem("current_user", JSON.stringify(u));
      setCurrentUser(u);
    }
  };
  const updateCurrentTradeUser = (tu) => {
    if (!tu) {
      setCurrentTradeUser(null);
      localStorage.removeItem("current_trade_user");
    } else {
      localStorage.setItem("current_trade_user", JSON.stringify(tu));
      setCurrentTradeUser(tu);
    }
  };
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        updateCurrentUser,
        currentTradeUser,
        updateCurrentTradeUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// export const useAuth = () => useContext(AuthContext);
