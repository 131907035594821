import { createContext, useState, useEffect } from "react";
import {
  getColors,
  getPriceFiles,
  // getCategories,
  getSubcategoriesWithCategories,
} from "../api_client/fetch_client";
export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [imageBaseUrl, setImageBaseUrl] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [catToSubcat, setCatToSubcat] = useState(new Map());
  const [subcatToCat, setSubcatToCat] = useState(new Map());
  const [viewCatId, setViewCatId] = useState();
  const [viewSubcatId, setViewSubcatId] = useState();
  const [allColors, setAllColors] = useState([]);
  const [forceReload, setForceReload] = useState(false);
  const [priceFiles, setPriceFiles] = useState([]);
  const [currentPriceFileKey, setCurrentPriceFileKey] = useState(null);
  useEffect(() => {
    const loadImageBaseUrl = async () => {
      const hostname = window.location.hostname;
      setImageBaseUrl(
        hostname === "localhost" || hostname.endsWith("evyty.com.au")
          ? "https://imgs.evyty.com.au"
          : "https://imgs.evyty.com"
      );
    };
    const loadSubcategories = async () => {
      const rsp = await getSubcategoriesWithCategories();
      if (!rsp.success) {
        throw new Error("failed loading getSubcategoriesWithCategories");
      }
      let cats = [];
      let uniqueCats = new Set();
      let cat2subcat = new Map();
      let subcat2cat = new Map();
      for (let i = 0; i < rsp.results.length; i++) {
        if (!uniqueCats.has(rsp.results[i].CAT_ID)) {
          uniqueCats.add(rsp.results[i].CAT_ID);
          cats.push({
            ID: rsp.results[i].CAT_ID,
            NAME: rsp.results[i].CAT_NAME,
            POSITION: rsp.results[i].CAT_POSITION,
          });
        }

        let arr = cat2subcat.get(`${rsp.results[i].CAT_ID}`);
        if (arr === undefined) {
          arr = [];
        }
        arr.push(rsp.results[i]);
        cat2subcat.set(`${rsp.results[i].CAT_ID}`, arr);
        let arr2 = subcat2cat.get(`${rsp.results[i].SUBCAT_ID}`);
        if (arr2 === undefined) {
          arr2 = [];
        }
        arr2.push(rsp.results[i]);
        subcat2cat.set(`${rsp.results[i].SUBCAT_ID}`, arr2);
      }
      // console.log("allCategories", cats);
      // console.log("cat2subcat:", cat2subcat);
      // console.log("subcat2cat:", subcat2cat);
      const sortedCats = cats.sort((a, b) => a.POSITION - b.POSITION);
      setAllCategories(sortedCats);
      setCatToSubcat(cat2subcat);
      setSubcatToCat(subcat2cat);
    };
    const loadColors = async () => {
      const rsp = await getColors();
      if (!rsp.success) {
        throw new Error("failed loading getSubcategoriesWithCategories");
      }
      setAllColors(rsp.results);
    };
    const loadPriceFiles = async () => {
      let rsp = await getPriceFiles();
      rsp = rsp.sort((a, b) => a.sort - b.sort);
      setPriceFiles(rsp);
      if (rsp && rsp.length > 0) {
        setCurrentPriceFileKey(rsp[0]);
      } else {
        setCurrentPriceFileKey(null);
      }
    };
    loadImageBaseUrl();
    loadSubcategories();
    loadColors();
    loadPriceFiles();
  }, [forceReload]);

  const reloadContext = () => {
    setForceReload((p) => !p);
  };

  return (
    <AppContext.Provider
      value={{
        imageBaseUrl,
        setImageBaseUrl,
        allCategories,
        catToSubcat,
        subcatToCat,
        viewCatId,
        setViewCatId,
        viewSubcatId,
        setViewSubcatId,
        reloadContext,
        allColors,
        priceFiles,
        currentPriceFileKey,
        setCurrentPriceFileKey,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
