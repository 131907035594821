import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  // Select,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";

export const SearchableSelect = ({
  title,
  allData,
  getItemName,
  getItemID,
  selectedID,
  onSelectChange,
  selectAll,
  margin,
  visibleFunc,
  error,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredData, setFilteredData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    // console.debug("SearchableSelect,", title, allData);
    const f = allData.filter((item) => getItemID(item) === selectedID);
    if (f && f.length > 0) {
      setSelectedItem(f[0]);
    } else {
      setSelectedItem(null);
    }
    let filtered = allData.filter((item) =>
      getItemName(item).toLowerCase().includes(searchKey.toLowerCase()),
    );
    if (visibleFunc) {
      filtered = filtered.filter((item) => visibleFunc(item));
    }
    setFilteredData(filtered);
  }, [searchKey, allData, getItemName, getItemID, selectedID, visibleFunc]);

  const onSearchChange = (e) => {
    setSearchKey(e.target.value);
  };
  const handleSelect = async (item) => {
    setSelectedItem(item);
    onClose();
    onSelectChange(item);
  };
  return (
    <FormControl mx={margin}>
      <FormLabel>{title}</FormLabel>
      <HStack spacing={2}>
        <Input
          value={getItemName(selectedItem) || `Select a ${title}`}
          disabled
        ></Input>
        <Menu isOpen={isOpen} onClose={onClose}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            onClick={onOpen}
          ></MenuButton>
          <MenuList>
            <Input
              placeholder={`Search ${title}...`}
              value={searchKey}
              onChange={onSearchChange}
              mb={2}
            />
            {selectAll && (
              <MenuItem key={-1} onClick={() => handleSelect(null)}>
                All
              </MenuItem>
            )}
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((item) => (
                <MenuItem
                  key={getItemID(item)}
                  onClick={() => handleSelect(item)}
                >
                  {getItemName(item)}
                </MenuItem>
              ))
            ) : (
              <Box />
            )}
          </MenuList>
        </Menu>
      </HStack>
      {error ? (
        <FormErrorMessage>{title} is required.</FormErrorMessage>
      ) : (
        <></>
      )}
    </FormControl>
  );
};
