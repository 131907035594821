import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  VStack,
  Spacer,
  HStack,
} from "@chakra-ui/react";
import { IoMdRefresh } from "react-icons/io";
import { useState, useEffect } from "react";

export const OperationBar = ({
  onNew,
  onDelete,
  onRefresh,
  isDisabled,
  searchStr,
  setSearchStr,
}) => {
  const [myKeyword, setMyKeyword] = useState("");
  useEffect(() => {
    setMyKeyword(searchStr);
  }, [searchStr]);

  const onSearchChange = (e) => {
    console.debug("onSearchChange:", e.target.value);
    setMyKeyword(e.target.value);
    if (!e.target.value) {
      setSearchStr(e.target.value);
    }
  };
  const onSearchEnter = (e) => {
    if (e.key === "Enter") {
      console.debug("onSearchEnter:", e.key, searchStr, myKeyword);
      setSearchStr(e.target.value);
      e.target.focus();
    }
  };
  const onRefreshClicked = () => {
    setSearchStr(myKeyword);
    onRefresh();
  };
  return (
    <Box w={"full"} px={2}>
      <VStack>
        <HStack>
          <Button onClick={onNew} p={2} minW={"100px"}>
            New
          </Button>
          <Button onClick={onDelete} disabled={isDisabled} m={2} minW={"100px"}>
            Delete
          </Button>
          <Spacer minW={"130px"} />
          <Input
            type="search"
            placeholder="Search"
            value={myKeyword}
            onChange={onSearchChange}
            onKeyDown={onSearchEnter}
            colorScheme="red"
          />
          <IconButton
            // display={{ base: "flex", md: "none" }}
            onClick={onRefreshClicked}
            variant="outline"
            aria-label="refresh"
            size={"md"}
            icon={<IoMdRefresh />}
          />
        </HStack>
        <Divider colorScheme="red" />
      </VStack>
    </Box>
  );
};
