export function HeroHeader({ title, breadcrumb }) {
  return (
    <div class="hero-area section-bg2">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="slider-area">
              <div class="slider-height2 slider-bg4 d-flex align-items-center justify-content-center">
                <div class="hero-caption hero-caption2">
                  <h2>{title}</h2>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb justify-content-center">
                      {breadcrumb.map((one) => (
                        <li class="breadcrumb-item">
                          <a href={one.link}>{one.name}</a>
                        </li>
                      ))}
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
