import { MainLaylout } from "../component/MainLayout";
import { HeroHeader } from "../component/HeroHeader";
import { CuttingForm } from "../component/CuttingForm";

export function FreeCutting() {
  return (
    <MainLaylout>
      <HeroHeader
        title={"Free Cutting Request Form"}
        breadcrumb={[
          { link: "/", name: "Home" },
          { link: "/free_cutting", name: "Free Cutting" },
        ]}
      />
      <div class="whole-wrap">
        <div class="container box_1170">
          <CuttingForm />
        </div>
      </div>
    </MainLaylout>
  );
}
