// theme.js
import { extendTheme } from "@chakra-ui/react";

const components = {
  Button: {
    defaultProps: {
      colorScheme: "red",
    },
  },
  Alert: {
    defaultProps: {
      colorScheme: "red",
    },
  },
  Badge: {
    defaultProps: {
      colorScheme: "purple",
    },
  },
  Tag: {
    defaultProps: {
      colorScheme: "green",
    },
  },
  Switch: {
    defaultProps: {
      colorScheme: "blue",
    },
  },
  // Add more components and their default color schemes as needed
};

const customTheme = extendTheme({ components });

export default customTheme;
